import { IOrderS, OrderActions, OrderActionTypes } from "redux/model";
import createReducer from "./createReducer";

export const order = createReducer<IOrderS>({} as IOrderS, {
  [OrderActions.FETCH_ORDER](state: {}, action: OrderActionTypes) {
    return action.payload;
  },
  [OrderActions.SET_ORDER](state: {}, action: OrderActionTypes) {
    return action.payload;
  },
  [OrderActions.UPDATE_ORDER](state: {}, action: OrderActionTypes) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
