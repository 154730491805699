import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { UserProfileActions } from "redux/model";
import userService from "services/user.service";
import { APIResponseCode } from "utility";
import { ENCRYPT_ADMIN_KEY } from "utility/apiSecret";

const OpenFromAdmin = () => {
  const { uuid = "" } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();

  const loadUserData = async ({ id = "" }: { id: string }) => {
    startLoading();
    const res = await userService.adminLogin({ id: id });
    finishLoading();

    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Email verify successfully!");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: {
          user: res?.data?.user,
          token: res?.data?.token,
          paymentMethods: res?.data?.paymentMethods,
        },
      });
      router("/dashboard");
    } else {
      router("/login");
    }
  };

  useEffect(() => {
    const encryptText = uuid?.replace(/Por21Ld/g, "/");
    const bytes = CryptoJS.AES.decrypt(encryptText, ENCRYPT_ADMIN_KEY);
    const id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))?.uuid ?? "";
    if (!Boolean(id)) return;

    loadUserData({ id });
  }, [uuid]);

  return <div>this page came from admin</div>;
};

export default OpenFromAdmin;
