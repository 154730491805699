import { Button, Grid } from "@mui/material";
import Logo from "components/Logo";
import AuthLayout from "layouts/AuthLayout";
import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContactSupport } from "pages/auth/Login";
import { ResetPasswordComponent} from "./ResetPasswordComponent";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import userService from "services/user.service";
import {
  APIResponseCode,
  errorProps,
  userInfoProps,
} from "utility/types";
import {
  passwordValidation,
} from "models/authHelper";
import { ENCRYPT_KEY } from "utility/apiSecret";

const ResetPassword:FC = () => {
  const router = useNavigate();
  const { uuid = "" } = useParams();
  const [error, setError] = useState<errorProps>({} as errorProps);
  const [data, setData] = useState<userInfoProps>({} as userInfoProps);
  const [user, setUser] = useState<any>({});
  const handleClickNext = async () => {
    const { valid, ...message } = passwordValidation({ data });
    if (!valid)
      return setError((prev) => ({
        ...prev,
        ...message,
      }));
    setError((prev) => ({ ...prev, ...message }));
    startLoading();
    const res = await userService.resetPassword({
      verifyData: user,
      data,
    });
    console.log(res);
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Password is updated successfully");
      router("/login");
    } else toast.warning(res?.message ?? "Something went wrong");
  }
  useEffect(() => {
    const encryptText = uuid?.replace(/Por21Ld/g, "/");
    const decData = CryptoJS.enc.Base64.parse(encryptText).toString(CryptoJS.enc.Utf8);
    const bytes = CryptoJS.AES.decrypt(decData, ENCRYPT_KEY).toString(CryptoJS.enc.Utf8);
    setUser(bytes ? JSON.parse(bytes) : "");
    //eslint-disable-next-line
  }, [uuid]);
  return (
    <AuthLayout>
      <Grid className="flex-1 py-10" container gap={2} direction="column">
        <Grid
          className="flex justify-center items-center pt-10 pb-6 px-10"
          item
        >
          <Logo size="large" />
        </Grid>
        <Grid
          className="px-10 bg-logo bg-no-repeat bg-cover bg-center flex-1 flex flex-col justify-end pb-32"
          item
        >
          <ResetPasswordComponent
            error={error}
            data={data}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }));
              setError((prev) => ({
                ...prev,
                [e.target.name]: "",
                compare: "",
              }));
            }}
          />
          <Button
            className="block w-full"
            variant="contained"
            size="large"
            onClick={() => handleClickNext()}
          >
            RESET PASSWORD
          </Button>
        </Grid>
        {/* <ContactSupport /> */}
      </Grid>
    </AuthLayout>
  );
};

export default ResetPassword;
