import React from "react";
import * as icons from "@mui/icons-material";
import { IUserAuth, IUserProfile } from "redux/model";

export type IError = {
  [key in keyof IUserAuth]: string;
};

export type eventType = React.ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement
> & { target: { name?: string; value: string } };
export type errorProps = {
  [key in keyof userInfoProps]: string;
};

export interface userInfoProps extends IUserProfile {
  confirmPassword?: string;
  compare?: boolean;
  phoneEmail?: boolean;
  terms?: boolean;
}

export interface phoneInputProps {
  phoneCode?: string;
  error: errorProps;
  data: string | userInfoProps;
  type: "email" | "phone";
  onChange: (e: eventType) => void;
  onChangePhoneCode?: (newCode: string) => void;
}

export interface addressRightProps {
  label: string;
  id: string;
  value: string;
}

export type Icons = keyof typeof icons;

enum APIResponseCode {
  SUCCESS = 1,
  FAILED = 0,
}

export interface APIResponseType<T> {
  code: APIResponseCode;
  data: T;
  message: string;
}

export type APITablePageResponseType<T> = {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  total: number;
  totalPage: number;
} & T;

export { APIResponseCode };

export interface Meal {
  id: number;
  name: string;
  subTitle: string;
  summary: string;
  image: string;
  weight: string;
  price: number;
}

export interface IMealPrice {
  id: number;
  boxCount: number;
  mealPrice: number;
  totalPrice: number;
}

export type TDeliveryDate = {
  weekNum: number;
  weekDay: number;
  weekYear: number;
};

export interface IBlackoutDay {
  id: number;
  weekNum: number;
  weekDay: number;
  weekYear: number;
  blackoutDay: Date;
}

export interface IMealS {
  mealPrices?: IMealPrice[];
  meals?: Meal[];
  selMealPrice?: IMealPrice;
}

export type addMinus = "minus" | "plus";
