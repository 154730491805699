import moment from "moment";
import { TDeliveryDate } from "utility/types";
import CustomText from "./CustomText";

const DeliveryDates = ({
  deliveryDate = {} as TDeliveryDate,
  availableDays = [],
  onClick = () => null,
}: {
  deliveryDate: TDeliveryDate;
  availableDays: TDeliveryDate[];
  onClick: (selItem: TDeliveryDate) => void;
}) => {
  return (
    <div className="flex justify-between overflow-x-auto gap-1">
      {availableDays.map((item, index) => {
        const day = moment().set({
          week: item.weekNum,
          day: item.weekDay,
          year: item.weekYear,
        });
        const selDay =
          deliveryDate.weekDay === item.weekDay &&
          deliveryDate.weekNum === item.weekNum &&
          deliveryDate.weekYear === item.weekYear;
        return (
          <div
            key={index}
            className={`rounded-lg flex flex-col items-center py-2 px-4 gap-2 cursor-pointer bg-${
              selDay ? "primary" : "bgColor"
            }`}
            onClick={() => onClick(item)}
          >
            <CustomText
              className={`text-sm font-pMedium text-${
                selDay ? "white" : "black"
              }`}
            >
              {day.format("ddd").toUpperCase()}
            </CustomText>
            <CustomText
              className={`font-pSemiBold text-2xl font-semibold text-${
                selDay ? "white" : "primary"
              }`}
            >
              {day.format("DD")}
            </CustomText>
            <CustomText
              className={`text-sm font-pMedium text-${
                selDay ? "white" : "black"
              }`}
            >
              {day.format("MMM").toUpperCase()}
            </CustomText>
          </div>
        );
      })}
    </div>
  );
};

export default DeliveryDates;
