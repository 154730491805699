import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CustomText from "components/CustomText";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { RootState } from "redux/reducers";
import userService from "services/user.service";
import { APIResponseCode } from "utility";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState({ password: "", confirmPassword: "" });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );

  const handleChangePassword = async () => {
    if (!password)
      return setError((prev) => ({
        ...prev,
        password: "This field is required",
      }));
    else
      setError((prev) => ({
        ...prev,
        password: "",
      }));

    if (!confirmPassword)
      return setError((prev) => ({
        ...prev,
        confirmPassword: "This field is required",
      }));
    else
      setError((prev) => ({
        ...prev,
        confirmPassword: "",
      }));

    if (password !== confirmPassword) {
      return toast.error("Password Confirm failed!");
    }

    startLoading();
    const res = await userService.update({
      data: user,
      password: true,
      confirmPassword: password,
      id: user.id,
    });
    finishLoading();
    if (res.code === APIResponseCode.FAILED) {
      toast.error(res.message);
    } else {
      toast.success("Password changed successfully");
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <CustomText className="text-lg font-pMedium">New Password</CustomText>
      <CustomText className="text-sm text-mBIcon">
        Secure your account with a new password
      </CustomText>

      <FormControl variant="outlined" fullWidth={true} className="">
        <InputLabel htmlFor="new-password">New Password</InputLabel>
        <OutlinedInput
          id="new-password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required={true}
          error={Boolean(error.password)}
        />
        <CustomText className="text-red-500 text-sm">
          {error.password}
        </CustomText>
      </FormControl>

      <FormControl variant="outlined" fullWidth={true} className="">
        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
        <OutlinedInput
          id="confirm-password"
          type={showConfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword((show) => !show)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
          error={Boolean(error.confirmPassword)}
        />
        <CustomText className="text-red-500 text-sm">
          {error.confirmPassword}
        </CustomText>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        size="large"
        className="capitalize"
        onClick={handleChangePassword}
      >
        Update
      </Button>
    </div>
  );
};

export default ChangePassword;
