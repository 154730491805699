import CustomText from "components/CustomText";
import { errorProps } from "utility/types";
import { MuiOtpInput } from "mui-one-time-password-input";

export const VerificationOtpSection = ({
  error = {} as errorProps,
  otp = "",
  verifyData = "",
  onChange = () => null,
  onClickResend = () => null,
  onComplete = () => null,
}: {
  verifyData?: string;
  error: errorProps;
  otp: string;
  onChange: (value: string) => void;
  onClickResend: () => void;
  onComplete: (value: string) => void;
}) => {
  return (
    <>
      <div>
        <CustomText className="font-pSemiBold" variant="h4">
          Verification Code
        </CustomText>
        <CustomText className="leading-6 pt-2 text-lg text-grayBlack">
          Please enter the verification code that we sent to your {verifyData}.
        </CustomText>
        <div className="py-8">
          <MuiOtpInput
            value={otp}
            onChange={onChange}
            fontSize="large"
            TextFieldsProps={{
              error: Boolean(error?.confirm_code),
            }}
            onComplete={onComplete}
          />
          {Boolean(error?.confirm_code ?? "") && (
            <CustomText className="text-red-600 text-sm pt-3">
              {error?.confirm_code ?? ""}
            </CustomText>
          )}
        </div>
        <div className="flex items-center pb-8">
          <CustomText className="text-lightGray">
            Didn't receive a code?
          </CustomText>
          <CustomText
            className="underline text-primary pl-2 cursor-pointer"
            onClick={onClickResend}
          >
            Resend
          </CustomText>
        </div>
      </div>
    </>
  );
};
