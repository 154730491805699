import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "utility/apiSecret";
import CreditCardDetail from "./CreditCardDetail";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
export const PaymentCardForm = () => {
  return (
    <div className="pt-4 flex">
      <Elements stripe={stripePromise}>
        <CreditCardDetail />
      </Elements>
    </div>
  );
};
