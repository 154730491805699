import {
  IUserProfile,
  UserProfileActions,
  UserProfileActionTypes,
} from "redux/model";
import { INITIAL_USER_PROFILE } from "utility/constantUtil";
import createReducer from "./createReducer";

export const userProfile = createReducer<IUserProfile>(INITIAL_USER_PROFILE, {
  [UserProfileActions.FETCH_USER_PROFILE](
    state: {},
    action: UserProfileActionTypes
  ) {
    return action.payload;
  },
  [UserProfileActions.SET_USER_PROFILE](
    state: {},
    action: UserProfileActionTypes
  ) {
    return action.payload;
  },
  [UserProfileActions.UPDATE_USER_PROFILE](
    state: {},
    action: UserProfileActionTypes
  ) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
