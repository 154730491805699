import Dashboard from "pages/dashboard";
import OrderDetail from "pages/meal/OrderDetail";
import ResetPassword from "pages/reset-password";
import { createBrowserRouter } from "react-router-dom";
import authRoutes from "./authRoutes";
import checkoutRoutes from "./checkoutRoute";
import orderRoutes from "./orderRoutes";
import accountRoutes from "./accountRoutes";
import PrivateRoute from "./PrivateRoute";
import mealsRoutes from "./mealsRoutes";
import activityRoutes from "./activityRoutes";
import Welcome from "pages/welcome";
import Meals from "pages/meals";
import MealsDetail from "pages/meals/MealsDetail";

const routes = createBrowserRouter([
  ...authRoutes,
  ...checkoutRoutes,
  ...orderRoutes,
  ...accountRoutes,
  ...mealsRoutes,
  ...activityRoutes,
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/meal/:id",
    element: (
      <PrivateRoute>
        <OrderDetail />
      </PrivateRoute>
    ),
  },
  {
    path: "/reset-password/:uuid",
    element: (
      <PrivateRoute>
        <ResetPassword />
      </PrivateRoute>
    ),
  },
  {
    path: "/welcome",
    element: (
      <PrivateRoute>
        <Welcome />
      </PrivateRoute>
    ),
  },
  {
    path: "/mealsview",
    element: (
      <Meals isAuth = {false} />
    ),
  },
]);

export default routes;
