import {
  AccountCircleOutlined,
  HomeOutlined,
  RestoreOutlined,
} from "@mui/icons-material";
import { AppBar, Toolbar } from "@mui/material";
import CustomText from "components/CustomText";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PRIMARY_BG_COLOR } from "theme/varialble";

export const NavItem = ({
  Icon = <></>,
  label = "",
  isSelected = false,
  onClick = () => {},
}: {
  Icon: JSX.Element;
  label: string;
  isSelected?: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`flex flex-col flex-1 w-1/3 items-center rounded-md px-4 py-1 hover:shadow cursor-pointer bg-${
        isSelected ? "primary" : "white"
      }`}
      onClick={onClick}
    >
      {React.cloneElement(Icon, {
        className: `text-mBIcon text-${isSelected ? "white" : "mBIcon"}`,
        fontSize: "large",
      })}
      <CustomText
        className={`capitalize text-sm font-pRegular text-${
          isSelected ? "white" : "mBIcon"
        }`}
      >
        {label}
      </CustomText>
    </div>
  );
};

const MobileBottom = () => {
  const navItems = [
    { icon: <HomeOutlined />, label: "dashboard" },
    { icon: <RestoreOutlined />, label: "activity" },
    { icon: <AccountCircleOutlined />, label: "account" },
  ];
  const { state = { pathName: "dashboard" } } = useLocation();
  const router = useNavigate();
  return (
    <AppBar
      position="fixed"
      component="nav"
      sx={{
        top: "auto",
        bottom: 0,
        background: PRIMARY_BG_COLOR,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}
      className="flex items-center shadow-none"
    >
      <div className="w-full mb:w-[444px] flex justify-between bg-gray-100 rounded-b-lg">
        <div className="flex justify-between items-center w-full my-1">
          {navItems.map((item, index) => (
            <NavItem
              Icon={item.icon}
              label={item.label}
              key={index}
              onClick={() =>
                router(`/${item.label}`, {
                  state: { ...state, pathName: item.label },
                })
              }
              isSelected={
                item.label === (state?.pathName ?? "dashboard") ? true : false
              }
            />
          ))}
        </div>
      </div>
    </AppBar>
  );
};

export default MobileBottom;
