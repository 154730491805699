import { FormControl, FormLabel, TextField } from "@mui/material";
import CustomText from "components/CustomText";
import PhoneTextInput from "components/PhoneTextInput";
import { errorProps, phoneInputProps, userInfoProps } from "utility/types";

export const EmailPhoneAfterOtpSection = ({
  phoneCode = "64",
  error = {} as errorProps,
  data = {} as userInfoProps,
  type = "email",
  onChange = () => null,
  onSkip = () => null,
  onChangePhoneCode = () => null,
}: phoneInputProps & { onSkip: () => void }) => {
  return (
    <>
      <div>
        <CustomText className="font-pSemiBold" variant="h4">
          Enter Your {type === "email" ? "Phone Number" : "Email"}
        </CustomText>
        <CustomText className="leading-6 pt-2 text-lg text-grayBlack">
          Please provide us with your{" "}
          {type === "email" ? "phone number" : "email address"}.
        </CustomText>
        <div className="py-8">
          <FormControl fullWidth>
            <FormLabel className="capitalize">
              {type === "email" ? "Phone" : "Email"}
            </FormLabel>
            {type === "email" ? (
              <PhoneTextInput
                phoneCode={phoneCode}
                data={(data as userInfoProps)?.phone ?? ""}
                onChangePhoneCode={onChangePhoneCode}
                onChange={onChange}
                type="phone"
                error={error}
              />
            ) : (
              <TextField
                name="email"
                value={(data as userInfoProps)?.email ?? ""}
                type="email"
                placeholder="email@example.com"
                fullWidth
                onChange={onChange}
                error={Boolean(error?.email)}
                helperText={error?.email ?? ""}
              />
            )}
          </FormControl>
        </div>
        <div className="flex items-center pb-8">
          <CustomText className="text-lightGray">
            Don’t have {type === "email" ? "phone number" : "an email address"}?
          </CustomText>
          <CustomText
            className="underline text-primary pl-2 cursor-pointer"
            onClick={onSkip}
          >
            Skip Step
          </CustomText>
        </div>
      </div>
    </>
  );
};
