export enum LoadingActions {
  LOADING = "LOADING",
  LOADED = "LOADED",
}

interface ILoadingAction<T, P> {
  type: T;
  payload: P;
}

export type LoadingActionTypes =
  | ILoadingAction<typeof LoadingActions.LOADING, boolean>
  | ILoadingAction<typeof LoadingActions.LOADED, boolean>;
