import Meals from "pages/meals";
import MealsDetail from "pages/meals/MealsDetail";
import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    path: "/meals",
    element: (
      <PrivateRoute>
        <Meals isAuth={true} />
      </PrivateRoute>
    ),
  },
  {
    path: "/meals/details",
    element: (
      <PrivateRoute>
        <MealsDetail />
      </PrivateRoute>
    ),
  },
];

export default routes;
