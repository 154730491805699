export interface IMeal {
  id: number;
  name: string;
  subTitle: string;
  summary: string;
  image: string;
  weight: string;
  price: number;
}

export interface IMealPrice {
  id: number;
  boxCount: number;
  mealPrice: number;
  totalPrice: number;
}

export interface IBlackoutDay {
  id: number;
  weekNum: number;
  weekDay: number;
  weekYear: number;
  blackoutDay: Date;
}

export interface IMealS {
  mealPrices?: IMealPrice[];
  meals?: IMeal[];
  selMealPrice?: IMealPrice;
}

export enum MealActions {
  FETCH_MEAL = 'FETCH_MEAL',
  SET_MEAL = 'SET_MEAL',
  UPDATE_MEAL = 'UPDATE_MEAL',
}

interface IMealActions<T, P> {
  type: T;
  payload: P;
}

export type MealActionTypes =
  | IMealActions<typeof MealActions.FETCH_MEAL, IMealS>
  | IMealActions<typeof MealActions.SET_MEAL, IMealS>
  | IMealActions<typeof MealActions.UPDATE_MEAL, IMealS>;
