import { apiPath } from "utility/apiPaths";
import { APIResponseCode, APIResponseType } from "utility/types";
import { APIService } from "./api.service";
import axios from 'utility/axios';

class FreightwayService extends APIService<any> {
  async checkNonUrban({
    url = '',
    postCode,
  }: {
    url: string;
    postCode: number;
  }): Promise<APIResponseType<any>> {
    try {
      const { data } = await axios.post(apiPath.FREIGHTWAY_SERVICE, {
        url,
        postCode,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<any>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: error,
        message: error?.response?.data?.message ?? 'Network Connection Problem',
      };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FreightwayService();
