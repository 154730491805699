import { RadioButtonChecked } from "@mui/icons-material";
import { Button } from "@mui/material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { IOrder } from "redux/model";
import CustomText from "./CustomText";

export const OrderProcess = ({
  year,
  weekNum,
  weekDay,
}: {
  year: number;
  weekNum: number;
  weekDay: number;
}) => {
  const curDay = moment().format("YYYY-MM-DD");
  const curTime = moment(`${curDay} 23:59`).unix();
  const deliveryTime = moment().set({
    day: weekDay,
    week: weekNum,
    year: year,
  });
  const transit =
    deliveryTime.subtract(1, "day").format("YYYY-MM-DD") === curDay;
  const deliveryType = deliveryTime.add(1, "day").unix() < curTime;
  return (
    <div className="flex justify-between text-mBIcon gap-[2px]">
      <div className="flex flex-col items-center w-1/3">
        <div className="flex items-center w-full">
          <hr className="w-1/2 border-dashed border-[1.5px] invisible" />
          <RadioButtonChecked color="primary" />
          <hr className="w-1/2 border-dashed border-[1.5px] border-primary" />
        </div>
        <CustomText className="text-sm text-primary">Processing</CustomText>
      </div>
      <div className="flex flex-col items-center w-1/3">
        <div className="flex items-center w-full">
          <hr
            className={`w-1/2 border-dashed border-[1.5px] border-${
              deliveryType || transit ? "primary" : "mBIcon"
            }`}
          />
          <RadioButtonChecked
            className={`text-${deliveryType || transit ? "primary" : "mBIcon"}`}
          />
          <hr
            className={`w-1/2 border-dashed border-[1.5px] border-${
              deliveryType || transit ? "primary" : "mBIcon"
            }`}
          />
        </div>
        <CustomText
          className={`text-sm text-${
            deliveryType || transit ? "primary" : "mBIcon"
          }`}
        >
          In Transit
        </CustomText>
      </div>
      <div className="flex flex-col items-center w-1/3">
        <div className="flex items-center w-full">
          <hr
            className={`w-1/2 border-dashed border-[1.5px] border-${
              deliveryType ? "primary" : "mBIcon"
            }`}
          />
          <RadioButtonChecked
            className={`text-${deliveryType ? "primary" : "mBIcon"}`}
          />
          <hr
            className={`w-1/2 border-dashed invisible border-[1.5px] border-${
              deliveryType ? "primary" : "mBIcon"
            }`}
          />
        </div>
        <CustomText
          className={`text-sm text-${deliveryType ? "primary" : "mBIcon"}`}
        >
          Delivered
        </CustomText>
      </div>
    </div>
  );
};

const OrderComponent = ({ order }: { order: IOrder }) => {
  const { state = {} } = useLocation();
  const router = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="p-4 bg-bgColor rounded-t-lg flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <CustomText className="text-mBIcon font-pMedium text-sm">
              Order ID
            </CustomText>
            <CustomText className="text-sm font-pSemiBold font-semibold">
              #{order.id}
            </CustomText>
          </div>
          <div className="flex flex-col items-end">
            <CustomText className="font-pMedium text-mBIcon text-sm">
              Delivery Schedule:
            </CustomText>
            <CustomText className="text-sm font-pSemiBold font-semibold">
              {moment()
                .set({
                  year: order.weekYear,
                  isoWeek: order.weekNum,
                  day: order.weekDay,
                })
                .format("dddd, DD MMMM")}
            </CustomText>
          </div>
        </div>
        <OrderProcess
          year={order.weekYear}
          weekNum={order.weekNum}
          weekDay={order.weekDay}
        />
      </div>
      <Button
        fullWidth={true}
        size="large"
        color="primary"
        variant="contained"
        className="capitalize rounded-lg rounded-t-none"
        onClick={() =>
          router(`/activity/${order.id}`, { state: { ...state, order: order } })
        }
      >
        view
      </Button>
    </div>
  );
};

export default OrderComponent;
