import { phone } from "phone";
import CryptoJS from "crypto-js";

export const isNumberString = (data: string) => {
  return /^\d+$/.test(data);
};

export const validateEmail = (email: string) => {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
};

export const validatePhoneNumber = (number: string) => {
  return phone(number)?.isValid ?? false;
};

export const Decrypt = (word: string, key: string) => {
  const decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(bytes).toString();
};

export const updateArray = (arr: any[], index: number, updateItem: any) => {
  return [...arr.slice(0, index), updateItem, ...arr.slice(index + 1)];
};

export const removeArray = (arr: any[], index: number) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];
