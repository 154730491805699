import CustomImage from "components/CustomImage";
import MobilePaper from "components/MobilePaper";
import OrderComponent from "components/OrderComponent";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IOrder } from "redux/model";
import { RootState } from "redux/reducers";
import orderService from "services/order.service";
import { APIResponseCode } from "utility/types";
import PACK_IMG from "assets/img/svg/package.svg";
import CustomText from "components/CustomText";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Activity = () => {
  const router = useNavigate();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );

  const loadUserOrders = async () => {
    startLoading();
    const res = await orderService.gets({ userId: user.id, delivered: true });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) setOrders(res.data);
    else toast.warning(res.message);
  };

  useEffect(() => {
    loadUserOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <MobilePaper className="flex flex-col px-4 py-6 gap-4">
        <BackTitle title="Previous Orders" url="" />
        {orders.length ? (
          <div className="flex flex-col gap-3 flex-1 basis-0 overflow-y-auto">
            {orders.map((item, index) => (
              <OrderComponent order={item} key={index} />
            ))}
          </div>
        ) : (
          <div className="h-full flex flex-col">
            <div className="py-60 bg-bgColor rounded-t-md flex flex-col items-center justify-center gap-2 flex-1 basis-0 overflow-y-auto">
              <CustomImage src={PACK_IMG} alt="" height={50} width={50} />
              <CustomText className="text-mBIcon">
                Your future orders will be displayed here
              </CustomText>
            </div>
            <Button
              color="primary"
              fullWidth={true}
              variant="contained"
              size="large"
              className="rounded-t-none normal-case text-lg font-pSemiBold"
              onClick={() => router("/order/add")}
            >
              Start a New Order
            </Button>
          </div>
        )}
      </MobilePaper>
    </Layout>
  );
};

export default Activity;
