import { Button } from "@mui/material";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { finishLoading, startLoading } from "redux/action";
import { OrderActions } from "redux/model";
import { RootState } from "redux/reducers";
import orderService from "services/order.service";
import { MEAL_PRICE } from "utility/apiSecret";
import { APIResponseCode } from "utility/types";
import { PriceText } from "./Checkout";

export const CheckoutPromoCode = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const orderP = useSelector((state: RootState) => state?.order ?? {});
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const price = ((orderP.order?.count ?? 0) * MEAL_PRICE).toFixed(2);
  const tax = ((orderP.order?.count ?? 0) * MEAL_PRICE * 0.15).toFixed(2);

  const handlePayPromocode = async () => {
    startLoading();
    const res = await orderService.addWithPromocode({
      data: { ...orderP.order, weekly: orderP.order?.weekly ?? true },
      userId: user?.id ?? "1",
      count: orderP.order?.count ?? 0,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({ type: OrderActions.SET_ORDER, payload: {} });
      router("/checkout/success", { state: res.data });
    } else router("/checkout/failed");
  };

  return (
    <Layout maxWidth="sm">
      <MobilePaper className="p-4 py-6 flex flex-col">
        <BackTitle title="Checkout" url="" />
        <div className="flex justify-between flex-col">
          <div className="py-3">
            <PriceText text="Total" value={`$${price}`} />
            <hr className="my-3" />
            <PriceText text="Delivery fee:" value="Free" />
            <PriceText text="GST 15%" value={`$${tax}`} />
            <hr className="my-3" />
            <PriceText
              text={
                <>
                  Promo Code:{" "}
                  <span className="text-primary font-pSemiBold">100OLK</span>
                </>
              }
              value={`-$${(Number(price) + Number(tax)).toFixed(2)}`}
              vClassName="font-pSemiBold text-primary"
            />
            <PriceText
              text="Total Cost"
              value="$0.00"
              vClassName="font-pSemiBold text-xl"
            />
            <hr className="my-3" />
          </div>
          <Button variant="contained" onClick={handlePayPromocode}>
            Continue
          </Button>
        </div>
      </MobilePaper>
    </Layout>
  );
};
