export const Z_INDEX_HEADER = 10;
export const DEFAULT_DRAWER_WIDTH = 250;
export const Z_INDEX_LOADING = 20;

export const MEAL_T_PRICE_6 = 79.98;
export const MEAL_T_PRICE_8 = 99.98;
export const MEAL_PRICE_8 = 12.5;
export const MEAL_PRICE_6 = 13.33;

export const paymentStatusOption = [
  { id: "1", label: "Card" },
  { id: "5", label: "Credit" },
  { id: "6", label: "Credit-Card" },
];
