import { CssBaseline, ThemeProvider } from "@mui/material";
import { PageLoadingProgress } from "common/PageLoadingProgress";
import { store } from "configStore";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "routes/pages";
import lightTheme from "theme/lightTheme";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <PageLoadingProgress />
        <RouterProvider router={routes} />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
