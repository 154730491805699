import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import LOGO from "assets/img/logo/logo.svg";
import IMG_ADDRESS from "assets/img/welcome/address.png";
import IMG_DELIVER from "assets/img/welcome/deliver.png";
import IMG_MEALS from "assets/img/welcome/meals.png";
import IMG_MORE from "assets/img/welcome/more.png";
import IMG_PAYMENT from "assets/img/welcome/payment.png";
import IMG_READY from "assets/img/welcome/ready.png";
import IMG_SCHEDULE from "assets/img/welcome/schedule.png";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

export interface ICarouselItem {
  imgSrc: any;
  screenHeader: string;
  screenBody: string;
}

const screenItems: ICarouselItem[] = [
  {
    imgSrc: IMG_ADDRESS,
    screenHeader: "enter your delivery address",
    screenBody:
      "Let's start by providing your home address. We'll check if we can deliver to your door. Currently, we serve urban and metro areas in the South Island. We'll make sure your meals reach you in no time!",
  },
  {
    imgSrc: IMG_MEALS,
    screenHeader: "Choose Your Meals",
    screenBody:
      "Pick your favorite meals from our app. You can choose either 6 or 8 meals from our regularly updated menu. We offer a range of 10-12 options, ensuring there's something for everyone",
  },
  {
    imgSrc: IMG_SCHEDULE,
    screenHeader: "Select Your Delivery Date",
    screenBody:
      "It's time to decide when you want your meals to arrive. Simply select the delivery date that suits you best. We'll make sure your food is freshly made and delivered on time",
  },
  {
    imgSrc: IMG_PAYMENT,
    screenHeader: "Confirm and Pay",
    screenBody:
      "Once you're happy with your meal selection, it's time to confirm your order. You can make payment using your debit or credit card. If you have a prepaid Timata voucher code, you can use that too. It's quick and convenient!",
  },
  {
    imgSrc: IMG_READY,
    screenHeader: "Your Fresh Meals are Made!",
    screenBody:
      "Our friends at Koha Kai will work their magic. They'll carefully prepare and pack your meals with love. We'll place them in an insulated box, lined with eco wool and gel ice packs and your meals will arrive at your doorstep within 24 hours",
  },
  {
    imgSrc: IMG_DELIVER,
    screenHeader: "Receive and Enjoy",
    screenBody:
      "Ding-dong! Your fresh meals have arrived. Simply refrigerate them for up to 3 days, and freeze after that. When you're ready to eat, pop them in the microwave and heat to your desired temperature. Just heat, eat and enjoy.",
  },
  {
    imgSrc: IMG_MORE,
    screenHeader: "Discover More Meals",
    screenBody:
      "Don't forget to check our app regularly for new meal options. We're always adding exciting dishes to our menu. Whenever you're ready for another round of tasty meals, place your next order and let the feasting continue!",
  },
];

const ScreenItem = ({
  step = 1,
  imgSrc,
  screenHeader = "",
  screenBody = "",
}: {
  step: number;
  imgSrc: any;
  screenHeader: string;
  screenBody: string;
}) => {
  return (
    <div className="flex flex-col items-center gap-4 h-[600px]">
      <CustomText className="text-primary text-xl font-pBold">
        Step {step}
      </CustomText>
      <div className="h-48 flex items-center">
        <CustomImage src={imgSrc} alt=""></CustomImage>
      </div>
      <CustomText className="text-primary text-xl font-pSemiBold capitalize">
        {screenHeader}
      </CustomText>
      <CustomText>{screenBody}</CustomText>
    </div>
  );
};

const Welcome = () => {
  const [step, setStep] = useState<number>(0);
  const router = useNavigate();

  const CustomArrow = ({ onClick = () => {}, label = "", className = "" }) => {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        className={`absolute bottom-10 ${className} z-[1]`}
      >
        {label}
      </Button>
    );
  };

  const renderNextArrow = (onClickHandler, hasNext, label) => {
    return (
      <CustomArrow
        onClick={() => (hasNext ? onClickHandler() : router("/order/add"))}
        className="right-0"
        label={hasNext ? "Next" : "Place Your Order!"}
      />
    );
  };

  return (
    <Layout>
      <MobilePaper className="p-6">
        <div className="flex flex-col gap-4 h-full">
          <div className="text-right" onClick={() => router("/")}>
            <Close className="cursor-pointer" />
          </div>
          {step === 0 ? (
            <div className="flex flex-col justify-between h-full flex-1 basis-0 overflow-y-auto">
              <div className="flex flex-col items-center gap-5">
                <CustomImage src={LOGO} alt="" height={100} width={100} />
                <CustomText className="text-primary font-pBold text-xl">
                  Welcome to Timata Meals
                </CustomText>
                <CustomText className="text-center">
                  Timata provides easy and affordable access to good quality,
                  nutritious, ready-made meals. <br />
                  Timata works for the time-poor professionals, for people who
                  like food, but don't like cooking, for people recovering from
                  illness or for those who just don't want to think about what
                  to make and all the hassle that goes with it. Get ready to
                  enjoy affordable, nutritious meals delivered to your door.
                </CustomText>
                <Button
                  color="primary"
                  fullWidth={true}
                  size="large"
                  variant="contained"
                  className="capitalize mb-5 mt-5"
                  onClick={() => setStep(1)}
                >
                  Learn More
                </Button>
              </div>
            </div>
          ) : (
            <Carousel
              showStatus={false}
              infiniteLoop={false}
              showThumbs={false}
              renderArrowPrev={() => <></>}
              renderArrowNext={renderNextArrow}
            >
              {screenItems.map((item, index) => (
                <ScreenItem step={index + 1} {...item} key={index} />
              ))}
            </Carousel>
          )}
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default Welcome;
