import { KeyboardBackspace } from "@mui/icons-material";
import { Button } from "@mui/material";
import CustomText from "components/CustomText";
import MealComponent from "components/MealComponent";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import mealsServices from "services/meals.services";
import { APIResponseCode, Meal } from "utility/types";

const Meals = ({ isAuth = true }: { isAuth: boolean }) => {
  const router = useNavigate();
  const [meals, setMeals] = useState<Meal[]>([]);

  const loadMeals = async () => {
    startLoading();
    const res = await mealsServices.gets();
    finishLoading();

    if (res.code === APIResponseCode.SUCCESS) setMeals(res.data);
    else toast.warning(res.message);
  };
  useEffect(() => {
    loadMeals();
  }, []);
  return (
    <Layout maxWidth="xs" noFooter={!isAuth}>
      <MobilePaper className="p-4 py-6 flex flex-1 flex-col gap-2">
        <div className="flex items-center" onClick={() => router("/")}>
          <KeyboardBackspace />
          <CustomText className="font-pSemiBold text-2xl ml-1">
            Meals
          </CustomText>
        </div>

        <div className="flex flex-col gap-2 flex-1 basis-0 overflow-y-auto">
          {meals?.length > 0
            ? meals.map((meal, index) => (
                <MealComponent
                  imgSrc={meal?.image}
                  mealName={meal?.name}
                  mealDesc={meal?.subTitle}
                  key={index}
                  onClick={() => (isAuth ? router("/meals/details") : null)}
                />
              ))
            : null}
        </div>
        {!isAuth ? (
          <Button
            className="block mt-2 p-3"
            variant="contained"
            fullWidth
            onClick={() => router("/login")}
          >
            Login or Sign Up
          </Button>
        ) : null}
      </MobilePaper>
    </Layout>
  );
};

export default Meals;
