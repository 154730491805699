import { APIResponseCode, APIResponseType } from "utility/types";

export class APIService<T = any> {
  async gets(p?: any): Promise<APIResponseType<T[]>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: [],
        message: "Not Defined (development)",
      });
    });
  }

  async get({ id = "" }: { id?: string }): Promise<APIResponseType<object>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: {},
        message: "Not Defined (development)",
      });
    });
  }

  async save({ data }: { data?: T }): Promise<APIResponseType<T>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: null as any,
        message: "Not Defined (development)",
      });
    });
  }

  async saves({
    data = [],
  }: {
    data?: Array<T>;
  }): Promise<APIResponseType<Array<string | boolean>>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: [],
        message: "Not Defined (development)",
      });
    });
  }

  async delete({ id = "" }: { id?: string }): Promise<APIResponseType<null>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: null,
        message: "Not Defined (development)",
      });
    });
  }

  async deletes({
    ids = [],
  }: {
    ids?: Array<string>;
  }): Promise<APIResponseType<null>> {
    return new Promise((resolve, reject) => {
      resolve({
        code: APIResponseCode.FAILED,
        data: null,
        message: "Not Defined (development)",
      });
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new APIService();
