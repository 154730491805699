import { apiPath } from "utility/apiPaths";
import axios from "utility/axios";
import {
  APIResponseCode,
  APIResponseType,
  IBlackoutDay,
  IMealPrice,
  Meal,
} from "utility/types";
import { APIService } from "./api.service";

class MealService extends APIService<Meal> {
  async gets(): Promise<APIResponseType<Meal[]>> {
    try {
      const { data } = await axios.get(apiPath.GET_MEALS);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<Meal[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [],
        message: "Network Connection Problem",
      };
    }
  }

  async getMealPrices(): Promise<APIResponseType<IMealPrice[]>> {
    try {
      const { data } = await axios.get(apiPath.GET_MEAL_PRICES);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IMealPrice[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [],
        message: "Network Connection Problem",
      };
    }
  }

  async getBlackoutDays(): Promise<APIResponseType<IBlackoutDay[]>> {
    try {
      const { data } = await axios.get(apiPath.BLACKOUT_DAYS);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IBlackoutDay[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [],
        message: 'Network Connection Problem',
      };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MealService();
