export interface addressRightProps {
  label: string;
  id: string;
  value: string;
  postCode?: number;
}

export interface IUserS {
  user: IUserProfile;
  token?: string;
  paymentMethods?: any[];
}

export interface IUserAuth extends IUserS {
  confirmPassword?: string;
  compare?: boolean;
  phoneEmail?: boolean;
  terms?: boolean;
  confirm_code: string;
}

export interface IUserProfile {
  id: string;
  phoneCode?: string;
  phone?: string;
  email?: string;
  password: string;
  firstName: string;
  lastName: string;
  birthday: string;
  address: addressRightProps;
  customerId?: string;
  confirm_code?: string;
  avatar?: string;
  paymentMethod?: string;
  balance: number;
  created_at: string;
}

export enum UserProfileActions {
  FETCH_USER_PROFILE = "FETCH_USER_PROFILE",
  SET_USER_PROFILE = "SET_USER_PROFILE",
  UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE",
}

interface IUserProfileAction<T, P> {
  type: T;
  payload: P;
}

export type UserProfileActionTypes =
  | IUserProfileAction<typeof UserProfileActions.FETCH_USER_PROFILE, IUserS>
  | IUserProfileAction<typeof UserProfileActions.SET_USER_PROFILE, IUserS>
  | IUserProfileAction<typeof UserProfileActions.UPDATE_USER_PROFILE, IUserS>;
