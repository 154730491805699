import { Button } from "@mui/material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { PaymentMethods, PriceText } from "pages/checkout";
import { BackTitle } from "pages/overview";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { finishLoading, startLoading } from "redux/action";
import { OrderActions, UserProfileActions } from "redux/model";
import { RootState } from "redux/reducers";
import orderService from "services/order.service";
import { APIResponseCode, eventType } from "utility/types";
import { MEAL_T_PRICE_6 } from "utility/variables";

const SplitPayment = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const order = useSelector((state: RootState) => state.order.order);
  const user = useSelector((state: RootState) => state.userProfile);
  const price = useSelector(
    (state: RootState) =>
      state?.meal?.selMealPrice?.totalPrice ?? MEAL_T_PRICE_6
  ) as unknown as number;

  const handleChangeCard = (v: eventType) => {
    const selPaymentMethod = user.paymentMethods?.find(
      (item) => item?.id === v.target.value
    );
    return selPaymentMethod?.id
      ? dispatch({
          type: OrderActions.UPDATE_ORDER,
          payload: { order: { ...order, paymentMethod: selPaymentMethod } },
        })
      : null;
  };

  const handlePay = async () => {
    startLoading();
    const orderData = {
      ...order,
      paid: "6",
      credit: +user.user.balance,
      card: +(price - user.user.balance).toFixed(2),
    };
    const res = await orderService.save({
      data: orderData,
      userId: user.user?.id ?? "1",
      count: order?.count ?? 6,
      customerId: user?.user?.customerId ?? "cus_No9vsYz6W6wVNH",
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: { ...user.user, balance: res.data?.balance ?? 0 } },
      });
      console.log({ data: res.data });
      router("/checkout/success", { state: res.data.order });
    } else router("/checkout/failed", { state: orderData });
    //   navigation.navigate("PaymentSuccess", { order: res.data.order });
    // } else navigation.navigate("PaymentFailed", { order: orderData });
  };

  return (
    <Layout maxWidth="sm">
      <MobilePaper className="p-4 py-6 flex flex-col gap-4">
        <BackTitle title="Split Payment" url="/checkout" />

        <div className="flex-1 flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <CustomText className="font-semibold text-base my-2">
              Split Payment with Card Payment:
            </CustomText>
            <PriceText text="Order Value" value={`$${price}`} vClassName="" />
            <PriceText
              text="Amount Paid via Credit"
              tClassName=""
              value={`-$${user.user.balance}`}
              vClassName="text-primary"
            />
            <PriceText
              text="Remaining Payment"
              tColor="black"
              tClassName="text-pSemiBold font-bold text-xl"
              value={`$${price - user.user.balance}`}
              vClassName="text-xl font-bold text-pSemiBold"
            />
          </div>
          <hr />
          <div className="flex flex-col gap-4">
            <CustomText className="text-pSemiBold text-base font-semibold">
              Select Payment Method:
            </CustomText>
            <PaymentMethods
              // payType={false}
              paymentMethods={user?.paymentMethods ?? []}
              value={order.paymentMethod?.id ?? ""}
              onChange={handleChangeCard}
            />
            <CustomText
              className="underline text-right"
              onClick={() => router("/checkout/payment/add")}
            >
              Add New Card
            </CustomText>
          </div>
          <Button
            size="large"
            fullWidth={true}
            className="normal-case mt-5"
            color="primary"
            variant="contained"
            onClick={handlePay}
          >
            Pay Now
          </Button>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default SplitPayment;
