import { Button } from "@mui/material";
import Logo from "components/Logo";
import ProgressBack from "components/ProgressBack";
import AuthLayout from "layouts/AuthLayout";
import {
  emailPhoneValidation,
  otpValidation,
  passwordValidation,
} from "models/authHelper";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { UserProfileActions } from "redux/model";
import userService from "services/user.service";
import { isNumberString } from "utility/helper";
import {
  APIResponseCode,
  errorProps,
  eventType,
  userInfoProps,
} from "utility/types";
import { ContactSupport } from "./Login";
import { EmailPhoneAfterOtpSection } from "./register/EmailPhoneAfterOtpSection";
import { EmailPhoneSection } from "./register/EmailPhoneSection";
import { PasswordSection } from "./register/PasswordSection";
import { VerificationOtpSection } from "./register/VerificationOtpSection";
import { VerificationSection } from "./register/VerificationSection";

const SingUp = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState<errorProps>({} as errorProps);
  const [phoneCode, setPhoneCode] = useState<string>("64");
  const [type, setType] = useState<"email" | "phone">("email");
  const [verifyData, setVerifyData] = useState<string>("");
  const [data, setData] = useState<userInfoProps>({} as userInfoProps);
  const [step, setStep] = useState<number>(0);
  const [terms, setTerms] = useState<boolean>(false);

  const handleBack = () => {
    return step === 0 ? router("/") : setStep((prev) => prev - 1);
  };

  const handleChangeVerify = (event: eventType) => {
    const { value } = event.target;
    const typeFlag = isNumberString(value);
    if (typeFlag && value.length > 1) setType("phone");
    else setType("email");
    setVerifyData(value);
    setError((prev) => ({ ...prev, [event.target.name]: "", phoneEmail: "" }));
  };

  const handleSkip = () => {
    delete data?.[type === "email" ? "phone" : "email"];
    setStep((prev) => prev + 1);
  };

  const handleClickNext = async (newData?: userInfoProps) => {
    try {
      if (step === 0) {
        const { valid, ...message } = emailPhoneValidation({
          type,
          verifyData,
          phoneCode,
        });
        if (!valid) return setError((prev) => ({ ...prev, ...message }));
        const res = await userService.accountValidation({
          verifyData,
          phoneCode,
          type,
        });
        if (res.code === APIResponseCode.SUCCESS) {
          if (!res.data) return getOtpVerifyCode({});
          else
            return setError((prev) => ({
              ...prev,
              [type]: `The ${type} you entered is already registered.`,
            }));
        } else toast.warning(res.message);
      }
      if (step === 1) {
        const { valid, confirm_code } = await otpValidation({
          type,
          verifyData,
          phoneCode,
          data: newData?.confirm_code ? newData : data,
        });
        if (!valid) {
          setError((prev) => ({
            ...prev,
            confirm_code: confirm_code,
          }));

          return toast.error("OTP verification failed");
        }
        setError((prev) => ({ ...prev, confirm_code: "" }));
        setStep((prev) => prev + 1);
      }
      if (step === 2) {
        const { valid, ...message } = emailPhoneValidation({
          type: type === "email" ? "phone" : "email",
          verifyData: type === "email" ? data?.phone ?? "" : data?.email ?? "",
          phoneCode,
        });
        if (!valid) return setError((prev) => ({ ...prev, ...message }));
        setStep((prev) => prev + 1);
      }
      if (step === 3) {
        const { valid, ...message } = passwordValidation({ data });
        if (!valid)
          return setError((prev) => ({
            ...prev,
            ...message,
          }));
        setError((prev) => ({ ...prev, ...message }));
        setStep((prev) => prev + 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickResend = () => {
    getOtpVerifyCode({ next: false });
  };

  const getOtpVerifyCode = async ({ next = true }: { next?: boolean }) => {
    startLoading();
    const res = await userService.sendOtp({
      type,
      verifyData: type === "email" ? verifyData : `+${phoneCode}${verifyData}`,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      if (next) setStep((prev) => prev + 1);
    } else return toast.warning("Something went wrong. Please try again.");
  };

  const handleChangeTerms = () => {
    setError((prev) => ({
      ...prev,
      terms: "",
    }));
    setTerms((prev) => !prev);
  };

  const handleSubmit = async () => {
    const validationFields = ["firstName", "lastName", "birthday", "address"];
    (validationFields as (keyof userInfoProps)[]).map((key) => {
      const ele =
        key === "address" ? Boolean(data?.[key]?.["id"]) : Boolean(data[key]);
      return setError((prev) => ({
        ...prev,
        [key]: ele ? "" : "This field is required",
      }));
    });
    const checkValidation =
      (validationFields as (keyof errorProps)[])
        .map((key) => Boolean(data?.[key]))
        .filter((item) => !item)?.length > 0
        ? true
        : false;
    if (checkValidation) return;
    if (!terms)
      return setError((prev) => ({ ...prev, terms: "This field is required" }));
    startLoading();
    const res = await userService.register({
      data,
      verifyData,
      type,
      phoneCode,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Account is created successfully");
      dispatch({
        type: UserProfileActions.SET_USER_PROFILE,
        payload: { user: res.data?.user ?? {}, token: res.data?.token ?? "" },
      });
      router("/dashboard");
    } else toast.warning(res?.message ?? "Something went wrong");
  };

  const handleComplete = (v: string) => {
    handleClickNext({ ...data, confirm_code: v });
  };
  return (
    <AuthLayout>
      <div className="p-10 flex flex-col flex-1 relative">
        <Logo helpIcon />
        <ProgressBack value={(1 / 6) * (step + 1) * 100} onBack={handleBack} />
        {step === 0 && (
          <EmailPhoneSection
            error={error}
            type={type}
            data={verifyData}
            phoneCode={phoneCode}
            onChange={handleChangeVerify}
            onChangePhoneCode={(newCode: string) => setPhoneCode(newCode)}
          />
        )}
        {step === 1 && (
          <VerificationOtpSection
            error={error}
            verifyData={
              type === "email" ? verifyData : `+${phoneCode}${verifyData}`
            }
            otp={data?.confirm_code ?? ""}
            onClickResend={handleClickResend}
            onChange={(newValue: string) => {
              setData((prev) => ({ ...prev, confirm_code: newValue }));
              setError((prev) => ({ ...prev, confirm_code: "" }));
            }}
            onComplete={handleComplete}
          />
        )}
        {step === 2 && (
          <EmailPhoneAfterOtpSection
            data={data}
            type={type}
            onChange={(e: eventType) => {
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
              setError((prev) => ({ ...prev, [e.target.name]: "" }));
            }}
            error={error}
            onChangePhoneCode={(newCode: string) => setPhoneCode(newCode)}
            onSkip={handleSkip}
          />
        )}
        {step === 3 && (
          <PasswordSection
            error={error}
            data={data}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }));
              setError((prev) => ({
                ...prev,
                [e.target.name]: "",
                compare: "",
              }));
            }}
          />
        )}
        {step === 4 && (
          <VerificationSection
            error={error}
            data={data}
            terms={terms}
            onChangeTerms={handleChangeTerms}
            onChange={(e) => {
              setError((prev) => ({ ...prev, [e.target.name]: "" }));
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
            }}
          />
        )}
        {step !== 4 ? (
          <Button
            className="block w-full"
            variant="contained"
            size="large"
            onClick={() => handleClickNext()}
          >
            Next
          </Button>
        ) : (
          <Button
            className="block w-full mt-6"
            variant="contained"
            size="large"
            onClick={handleSubmit}
          >
            Create Account
          </Button>
        )}
        {/* {step === 0 && (
          <div className="pb-7 bottom-0 left-0 absolute w-full">
            <ContactSupport />
          </div>
        )} */}
      </div>
    </AuthLayout>
  );
};

export default SingUp;
