import axiosLib from "axios";
// import { persistor } from "configStore";
import { API_URL } from "./apiSecret";

const instance = axiosLib.create({
  baseURL: `${API_URL}/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = null;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export default instance;
