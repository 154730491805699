import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import CustomText from "components/CustomText";
import * as React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { OrderActions, UserProfileActions } from "redux/model";
import { RootState } from "redux/reducers";
import userService from "services/user.service";
import { APIResponseCode } from "utility/types";
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "./PaymentCardField";

type StripeChangeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent;

export default function CreditCardDetail() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const router = useNavigate();
  const orderP = useSelector((state: RootState) => state?.order ?? {});
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const [pError, setError] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });
  const [cardName, setCardName] = React.useState<string>("");

  const disabled =
    !pError.cardNumberComplete ||
    !pError.expiredComplete ||
    !pError.cvcComplete;

  const onElementChange =
    (field: string, errorField: string) => (event: StripeChangeEvent) => {
      const { complete, error } = event;
      setError({
        ...pError,
        [field]: complete,
        [errorField]: error?.message ?? null,
      });
    };

  const { cardNumberError, expiredError, cvcError } = pError;

  const handleAddPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }
    startLoading();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    finishLoading();
    if (error) return toast.warning(error.message);
    const res = await userService.attachPaymentCard({
      paymentMethodId: paymentMethod.id,
      userId: user?.id ?? "1",
    });
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({
        type: OrderActions.UPDATE_ORDER,
        payload: { order: { ...orderP.order, paymentMethod: paymentMethod } },
      });
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: { ...user, customerId: res.data } },
      });
      router(-1);
    } else {
      toast.warning(res.message);
    }
  };

  return (
    <div className="flex flex-col flex-1 justify-between">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomText className="font-pMedium text-sm pb-1">
            Card Number
          </CustomText>
          <StripeTextFieldNumber
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            onChange={onElementChange("cardNumberComplete", "cardNumberError")}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomText className="font-pMedium text-sm pb-1">Name</CustomText>
          <TextField
            placeholder="Name on card"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            fullWidth
            sx={{ fontSize: 20 }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomText className="font-pMedium text-sm pb-1">
            Expiration date
          </CustomText>
          <StripeTextFieldExpiry
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            onChange={onElementChange("expiredComplete", "expiredError")}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomText className="font-pMedium text-sm pb-1">CVV</CustomText>
          <StripeTextFieldCVC
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError}
            onChange={onElementChange("cvcComplete", "cvcError")}
          />
        </Grid>
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      </Grid>
      <Button
        className={`${
          disabled ? "bg-[#0000001f] text-[#00000042]" : "bg-primary text-white"
        } mt-5`}
        variant="contained"
        size="large"
        fullWidth
        disabled={disabled}
        onClick={handleAddPayment}
      >
        Continue
      </Button>
    </div>
  );
}
