import { Button, Grid } from "@mui/material";
import Logo from "components/Logo";
import AuthLayout from "layouts/AuthLayout";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContactSupport } from "./Login";
import { INTERCOM_ID } from "utility";
import CustomText from "components/CustomText";

const Home: FC = () => {
  const router = useNavigate();
  useEffect(() => {
    // login();
    (window as any).Intercom("boot", {
      app_id: INTERCOM_ID,
      //Website visitor so may not have any user related info
    });
    //eslint-disable-next-line
  }, []);
  return (
    <AuthLayout>
      <Grid className="flex-1 py-10" container gap={2} direction="column">
        <Grid
          className="flex justify-center items-center pt-10 pb-6 px-10"
          item
        >
          <Logo size="large" />
        </Grid>
        <Grid
          className="px-10 bg-logo bg-no-repeat bg-cover bg-center flex-1 flex flex-col justify-end pb-32"
          item
        >
          <Button
            className="inline-flex p-3"
            variant="contained"
            fullWidth
            onClick={() => router("/login")}
          >
            Login
          </Button>
          <Button
            className="block mt-2 p-3 mb-5"
            variant="outlined"
            fullWidth
            onClick={() => router("/register")}
          >
            Create New Account
          </Button>
          <CustomText
            className="text-center cursor-pointer text-primary pt-5 underline"
            onClick={() => router("/mealsview")}
          >
            View our Meals Without an Account
          </CustomText>
        </Grid>
        {/* <ContactSupport /> */}
      </Grid>
    </AuthLayout>
  );
};

export default Home;
