// import { IAddress } from '@src/components';
// import { apiPath } from '@src/utility/apiPaths';
// import axios from '@src/utility/axios';
// import { APIResponseCode, APIResponseType } from '@src/utility/types';
// import { APIService } from './api.service';

import { APIResponseCode, APIResponseType, addressRightProps, apiPath } from "utility";
import { APIService } from "./api.service";
import axios from 'utility/axios';

interface IJoinWaitlist {
  exist: boolean;
  success: boolean;
}

class WaitlistService extends APIService<any> {
  async joinWaitlist({
    userId = '',
    address = {} as addressRightProps,
    postCode,
  }: {
    userId: string;
    postCode: number;
    address: addressRightProps;
  }): Promise<APIResponseType<IJoinWaitlist>> {
    try {
      const { data } = await axios.post(apiPath.WAITLIST_JOIN, {
        userId,
        postCode,
        address,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IJoinWaitlist>;
    } catch (error: any) {
      console.log(error)
      return {
        code: APIResponseCode.FAILED,
        data: {} as IJoinWaitlist,
        message: error?.response?.data?.message ?? 'Network Connection Problem',
      };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WaitlistService();
