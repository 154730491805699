import { Button } from "@mui/material";
import CustomLink from "components/CustomLink";
import CustomText from "components/CustomText";
import Logo from "components/Logo";
import ProgressBack from "components/ProgressBack";
import AuthLayout from "layouts/AuthLayout";
import { emailPhoneValidation, otpValidation } from "models/authHelper";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { UserProfileActions } from "redux/model";
import userService from "services/user.service";
import { isNumberString } from "utility/helper";
import {
  APIResponseCode,
  errorProps,
  eventType,
  userInfoProps,
} from "utility/types";
import PasswordSection from "./login/PasswordSection";
import { EmailPhoneSection } from "./register/EmailPhoneSection";
import { VerificationOtpSection } from "./register/VerificationOtpSection";

export const ContactSupport = () => {
  return (
    <CustomText className="text-center pb-5 font-pMedium text-lightGray">
      Need Help?&nbsp;
      <CustomLink className="text-primary underline" href="/support">
        Contact Support
      </CustomLink>
    </CustomText>
  );
};

const Login: FC = () => {
  const router = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState<errorProps>({} as errorProps);
  const [step, setStep] = useState<number>(0);
  const [phoneCode, setPhoneCode] = useState<string>("64");
  const [type, setType] = useState<"email" | "phone">("email");
  const [verifyData, setVerifyData] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [data, setData] = useState<userInfoProps>({} as userInfoProps);

  const handleBack = () => {
    return step === 0 ? router("/#") : setStep((prev) => prev - 1);
  };

  const handleChangeVerify = (event: eventType) => {
    const { value } = event.target;
    const typeFlag = isNumberString(value);
    if (typeFlag && value.length > 1) setType("phone");
    else setType("email");
    setVerifyData(value);
    setError((prev) => ({ ...prev, [event.target.name]: "", phoneEmail: "" }));
  };

  const handleClickNext = async () => {
    try {
      if (step === 0) {
        const { valid, ...message } = emailPhoneValidation({
          type,
          verifyData,
          phoneCode,
        });
        if (!valid) return setError((prev) => ({ ...prev, ...message }));
        //account validation check on the database
        const res = await userService.accountValidation({
          verifyData,
          phoneCode,
          type,
        });
        if (res.code === APIResponseCode.SUCCESS) {
          if (res.data) return setStep((prev) => prev + 1);
          return setError((prev) => ({
            ...prev,
            [type]: `The ${type} you entered is not registered.`,
          }));
        } else toast.warning(res.message);
      }
    } catch (err) {
      return toast.warning("Something went wrong. Please try again.");
    }
  };

  const handleLoginCode = async (next: boolean) => {
    const res = await userService.sendOtp({
      type,
      verifyData: type === "email" ? verifyData : `+${phoneCode}${verifyData}`,
    });
    if (res.code === APIResponseCode.SUCCESS) {
      if (next) setStep((prev) => prev + 1);
    } else return toast.warning("Something went wrong. Please try again.");
  };

  const handleLogin = async () => {
    if (!password)
      return setError((prev) => ({
        ...prev,
        password: "This field is required",
      }));
    startLoading();
    const res = await userService.login({
      verifyData: verifyData,
      password,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({
        type: UserProfileActions.SET_USER_PROFILE,
        payload: { user: res.data?.user ?? {}, token: res.data?.token ?? "" },
      });

      (window as any).Intercom("shutdown");
      router("/dashboard");
    } else {
      toast.warning(res.message);
    }
  };

  const handleLoginWithCode = async (newData?: userInfoProps) => {
    const { valid, confirm_code } = await otpValidation({
      type,
      verifyData,
      phoneCode,
      data: newData?.confirm_code ? newData : data,
    });
    if (!valid)
      return setError((prev) => ({
        ...prev,
        confirm_code: confirm_code,
      }));
    startLoading();
    const res = await userService.loginCode({
      verifyData: verifyData,
      password,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Login successfully");
      dispatch({
        type: UserProfileActions.SET_USER_PROFILE,
        payload: { user: res.data?.user ?? {}, token: res.data?.token ?? "" },
      });
      router("/dashboard");
    } else toast.warning(res?.message ?? "Something went wrong");
  };

  const handleComplete = (v: string) => {
    handleLoginWithCode({ ...data, confirm_code: v });
  };
  return (
    <AuthLayout>
      <div className="p-10 flex flex-1 flex-col relative">
        <Logo helpIcon />
        <ProgressBack value={(1 / 3) * (step + 1) * 100} onBack={handleBack} />
        {step === 0 ? (
          <>
            <EmailPhoneSection
              authType="login"
              error={error}
              type={type}
              data={verifyData}
              phoneCode={phoneCode}
              onChange={handleChangeVerify}
              onChangePhoneCode={(newCode: string) => setPhoneCode(newCode)}
            />
            <Button
              className="block w-full"
              variant="contained"
              size="large"
              onClick={handleClickNext}
            >
              Next
            </Button>
          </>
        ) : step === 1 ? (
          <PasswordSection
            error={error}
            password={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError((prev) => ({ ...prev, password: "" }));
            }}
            onClick={handleLogin}
            onLoginCode={() => handleLoginCode(true)}
          />
        ) : (
          <>
            <VerificationOtpSection
              verifyData={
                type === "email" ? verifyData : `+${phoneCode}${verifyData}`
              }
              error={error}
              otp={data?.confirm_code ?? ""}
              onClickResend={() => handleLoginCode(false)}
              onChange={(newValue: string) => {
                setData((prev) => ({ ...prev, confirm_code: newValue }));
                setError((prev) => ({ ...prev, confirm_code: "" }));
              }}
              onComplete={handleComplete}
            />
            <Button
              className="block w-full my-8"
              variant="contained"
              size="large"
              onClick={() => handleLoginWithCode()}
            >
              Login
            </Button>
          </>
        )}
        {/* <div className="pb-7 bottom-0 left-0 absolute w-full">
          <ContactSupport />
        </div> */}
      </div>
    </AuthLayout>
  );
};

export default Login;
