import { Breakpoint, Container, Toolbar } from "@mui/material";
import { FC, PropsWithChildren, useEffect } from "react";
import Header from "./Header";
import HtmlContainer from "./HtmlContainer";
import MobileBottom from "./MobileBottom";

interface LayoutProps {
  noHeader?: boolean;
  maxWidth?: Breakpoint;
  noFooter?: boolean;
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  noHeader = false,
  maxWidth = "xs",
  noFooter = false,
  children,
}) => {
  return (
    <HtmlContainer>
      <Container
        className="mt-7 flex-1 flex flex-col basis-0 overflow-y-auto"
        disableGutters
        maxWidth={maxWidth}
      >
        {!noHeader && <Header />}
        <Toolbar />
        {children}
        {!noFooter ? <MobileBottom /> : null}
      </Container>
    </HtmlContainer>
  );
};

export default Layout;
