import { FormControl, FormLabel, TextField } from "@mui/material";
import CustomText from "components/CustomText";
import { errorProps, eventType, userInfoProps } from "utility/types";

export const ResetPasswordComponent = ({
  error = {} as errorProps,
  data = {} as userInfoProps,
  onChange = () => null,
}: {
  error: errorProps;
  data: userInfoProps;
  onChange: (e: eventType) => void;
}) => {
  return (
    <>
      <div>
        <CustomText className="font-pSemiBold" variant="h4">
          Reset Password
        </CustomText>
      </div>
      <div className="py-8">
        <FormControl className="py-2" fullWidth>
          <FormLabel>
            New Password
          </FormLabel>
          <TextField
            name="password"
            value={data?.password ?? ""}
            type="password"
            placeholder="******"
            fullWidth
            onChange={onChange}
            error={Boolean(error?.password)}
            helperText={error?.password}
          />
        </FormControl>
        <FormControl className="py-2" fullWidth>
          <FormLabel>Confirm Password</FormLabel>
          <TextField
            name="confirmPassword"
            value={data?.confirmPassword ?? ""}
            type="password"
            fullWidth
            placeholder="******"
            onChange={onChange}
            error={Boolean(error?.confirmPassword)}
            helperText={error?.confirmPassword ?? ""}
          />
        </FormControl>
        {Boolean(error?.compare) && (
          <CustomText className="text-red-600" variant="caption">
            {error?.compare}
          </CustomText>
        )}
      </div>
    </>
  );
};
