import { Grid, Paper } from "@mui/material";
import CustomImage from "./CustomImage";
import CustomText from "./CustomText";

const MealComponent = ({
  imgSrc = "",
  mealName = "Meal Header",
  mealDesc = "Meal content",
  className = "",
  onClick = () => {},
}) => {
  return (
    <Paper
      className={`bg-bgColor rounded-md ${className} p-2`}
      onClick={onClick}
    >
      <Grid
        container
        // spacing={2}
      >
        <Grid item xs={3}>
          <CustomImage src={imgSrc} height={100} />
        </Grid>
        <Grid item xs={9} className="pl-2 flex flex-col justify-center">
          <CustomText className="font-pBold text-base font-semibold">
            {mealName}
          </CustomText>
          <CustomText className="font-pRegular text-mBIcon line-clamp-2 text-sm">
            {mealDesc}
          </CustomText>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MealComponent;
