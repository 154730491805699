import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinearProgress from "@mui/material/LinearProgress";

const ProgressBack = ({
  value = 0,
  onBack = () => {},
}: {
  value: number;
  onBack?: () => void;
}) => {
  return (
    <div className="flex items-center py-7">
      <KeyboardBackspaceIcon
        className="mr-3 cursor-pointer"
        fontSize="medium"
        onClick={onBack}
      />
      <LinearProgress
        className="h-2 flex-1 rounded"
        variant="determinate"
        value={value}
      />
    </div>
  );
};

export default ProgressBack;
