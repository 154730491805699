import { Backdrop, CircularProgress } from "@mui/material";
import logo from "assets/img/logo/loading-logo.svg";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Z_INDEX_LOADING } from "utility/variables";
import CustomImage from "./CustomImage";

const PageLoading = () => {
  const isLoading = useSelector(
    (state: RootState) => state?.isLoading ?? false
  );
  return (
    <div className="relative w-full h-full">
      {isLoading && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + Z_INDEX_LOADING,
            backdropFilter: "blur(2px)",
          }}
          open={Boolean(isLoading)}
        >
          <div className="relative">
            <div className="">
              <CustomImage
                src={logo}
                alt="timata logo"
                width={120}
                height={120}
              />
            </div>
            <div className="absolute top-0 w-full h-full">
              <CircularProgress className="w-full h-full text-lightBgGray" />
            </div>
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default PageLoading;
