import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { addressRightGetAutoComplete } from "models/third/addressRight";
import { addressRightProps, errorProps, eventType } from "utility/types";

interface AddressPickerProps<T> {
  error?: errorProps;
  label?: string;
  placeholder?: string;
  errorStatus?: boolean;
  helperText?: string;
  errorText?: string;
  value?: T | null;
  onChange: (value: T) => void;
}
const AddressPickerComponent: React.FC<
  AddressPickerProps<addressRightProps>
> = ({
  value = null,
  placeholder = "",
  label = "",
  error = {} as errorProps,
  onChange = () => {},
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<addressRightProps[]>([]);
  // const [error, setError] = useState<boolean>(false)
  const [text, setText] = useState<string>("");

  const loading = useMemo(
    () => open && options.length === 0 && text.length >= 3,
    [text, options, open]
  );

  const handleText = (e: eventType) => setText(e?.target?.value ?? "");

  useEffect(() => {
    let active = true;
    // if (!loading) {
    //   return undefined
    // }
    (async () => {
      const newOptions = await addressRightGetAutoComplete({ term: text });
      if (active) {
        setOptions(newOptions);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, text]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option?.label ?? ""}
        options={options}
        loading={loading}
        value={value ?? null}
        onChange={(e, v: any) => onChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            {...(label ? { label: label } : {})}
            value={text}
            onChange={handleText}
            error={Boolean(error?.address)}
            helperText={error?.address ?? ""}
          />
        )}
      />
    </div>
  );
};

export default AddressPickerComponent;
