import { countries } from "utility/countries";
import { errorProps, phoneInputProps } from "utility/types";
import { Grid, InputAdornment, TextField } from "@mui/material";
import PhoneCodeAutoComplete from "./PhoneCodeSelect";

const PhoneTextInput = ({
  error = {} as errorProps,
  phoneCode = "64",
  data = "",
  type = "email",
  onChange = () => null,
  onChangePhoneCode = () => {},
}: phoneInputProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <PhoneCodeAutoComplete
          options={countries}
          value={phoneCode}
          onChange={onChangePhoneCode}
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          value={data}
          onChange={onChange}
          name={type}
          type="text"
          autoFocus
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">+{phoneCode}</InputAdornment>
          //   ),
          // }}
          fullWidth
          error={Boolean(error?.["phone"] ?? "")}
          helperText={error?.["phone"] ?? ""}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneTextInput;
