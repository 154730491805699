import { store } from "configStore";
import { LoadingActions, LoadingActionTypes } from "redux/model";

export const startLoading = (): LoadingActionTypes => {
  return store.dispatch({
    type: LoadingActions.LOADING,
    payload: true,
  });
};

export const finishLoading = (): LoadingActionTypes => {
  return store.dispatch({
    type: LoadingActions.LOADED,
    payload: false,
  });
};
