import { Button } from "@mui/material";
import CustomText from "components/CustomText";
import { emailPhoneValidation, otpValidation } from "models/authHelper";
import { VerificationOtpSection } from "pages/auth/register/VerificationOtpSection";
import { useEffect, useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IUserProfile, UserProfileActions } from "redux/model";
import userService from "services/user.service";
import {
  APIResponseCode,
  errorProps,
  userInfoProps
} from "utility/types";

export interface IPhoneNumber {
  phoneCode: string;
  phone: string;
}

const EditPhone = ({
  user = {} as IUserProfile,
  onSuccess = () => {},
}: {
  user: IUserProfile;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(0);
  const [newPhone, setNewPhone] = useState<IPhoneNumber>({} as IPhoneNumber);
  const [error, setError] = useState<errorProps>({} as errorProps);
  const [userData, setUserData] = useState<IUserProfile>({} as IUserProfile);

  const getOtpVerifyCode = async ({ next = true }: { next?: boolean }) => {
    startLoading();
    const res = await userService.sendOtp({
      type: "phone",
      verifyData: `+${newPhone?.phoneCode}${newPhone?.phone}`,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      if (next) setStep((prev) => prev + 1);
    } else
      return toast.error(
        res?.message ?? "Something went wrong. Please try again."
      );
  };

  const handleUpdate = async () => {
    const { valid, ...message } = emailPhoneValidation({
      type: "phone",
      verifyData: newPhone?.phone ?? "",
      phoneCode: newPhone?.phoneCode,
    });
    if (!valid) {
      return setError((prev) => ({ ...prev, ...message }));
    }
    const res = await userService.accountValidation({
      verifyData: newPhone?.phone ?? "",
      phoneCode: newPhone?.phoneCode,
    });

    if (res.code === APIResponseCode.SUCCESS) {
      if (!res.data) return getOtpVerifyCode({});
      return setError((prev) => ({
        ...prev,
        phone: "The phone you entered is already registered.",
      }));
    } else
      toast.error(
        res.message ?? "The phone you entered is already registered."
      );
  };

  const handleClickResend = async () => {
    getOtpVerifyCode({ next: false });
  };

  const handleComplete = async (newCode: string) => {
    const { valid, confirm_code } = await otpValidation({
      type: "phone",
      verifyData: newPhone?.phone ?? "",
      phoneCode: newPhone?.phoneCode,
      data: { confirm_code: newCode } as userInfoProps,
    });
    if (!valid)
      return setError((prev) => ({
        ...prev,
        confirm_code: confirm_code,
      }));
    startLoading();
    const res = await userService.update({
      data: {
        id: userData?.id,
        phoneCode: newPhone?.phoneCode,
        phone: newPhone?.phone,
      } as IUserProfile,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success(res.message ?? "Account info updated successfully");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: res.data ?? {} },
      });
      onSuccess();
      // router("/account/edit", { state: { ...state, pathName: "account" } });
    } else
      return toast.error(
        res?.message ?? "Something went wrong. Please try again."
      );
  };

  useEffect(() => {
    const { phone = "", phoneCode = "" } = user;
    setNewPhone({ phone, phoneCode });
    setUserData(user);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="">
      {step === 0 ? (
        <div className="flex flex-col gap-3">
          <CustomText className="text-lg font-semibold">
            Phone Number
          </CustomText>
          <CustomText className="text-sm font-pRegular text-mBIcon">
            Upgrade your mobile for a personalized experience
          </CustomText>
          <PhoneInput
            country={"nz"}
            value={newPhone.phoneCode + newPhone.phone}
            onChange={(fullNumber, data: CountryData) => {
              const { dialCode = "" as string } = data;
              const phone = fullNumber.replace(dialCode, "");
              setNewPhone({ phone, phoneCode: dialCode });
            }}
          />
          <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            className="capitalize"
            size="large"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      ) : (
        <div>
          <VerificationOtpSection
            verifyData={`+${newPhone?.phoneCode}${newPhone?.phone}`}
            error={error}
            otp={userData?.confirm_code ?? ""}
            onClickResend={handleClickResend}
            onChange={(newValue: string) => {
              setUserData((prev) => ({ ...prev, confirm_code: newValue }));
              setError((prev) => ({ ...prev, confirm_code: "" }));
            }}
            onComplete={handleComplete}
          />
        </div>
      )}
    </div>
  );
};

export default EditPhone;
