import { Button, TextField } from "@mui/material";
import CustomText from "components/CustomText";
import { emailPhoneValidation, otpValidation } from "models/authHelper";
import { VerificationOtpSection } from "pages/auth/register/VerificationOtpSection";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IUserProfile, UserProfileActions } from "redux/model";
import userService from "services/user.service";
import {
  APIResponseCode,
  errorProps,
  eventType,
  userInfoProps,
} from "utility/types";

const EditEmail = ({
  user = {} as IUserProfile,
  onSuccess = () => {},
}: {
  user: IUserProfile;
  onSuccess: () => void;
}) => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<errorProps>({} as errorProps);
  const [confirmCode, setConfirmCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const dispatch = useDispatch();

  const getOtpVerifyCode = async ({ next = true }: { next?: boolean }) => {
    startLoading();
    const res = await userService.sendOtp({
      type: "email",
      verifyData: user?.email ?? "",
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      console.log({ step });
      if (next) setStep((prev) => prev + 1);
      console.log({ step });
    } else
      return toast.error(
        res?.message ?? "Something went wrong. Please try again."
      );
  };

  const handleUpdate = async () => {
    if (step === 0) {
      const { valid, ...message } = emailPhoneValidation({
        type: "email",
        verifyData: email ?? "",
      });
      if (!valid) {
        return setError(message?.["email"] ?? "");
      }
      //account validation check on the database
      const res = await userService.accountValidation({
        verifyData: email ?? "",
        type: "email",
      });
      if (res.code === APIResponseCode.SUCCESS) {
        if (!res.data) return getOtpVerifyCode({});
        else
          return setError((prev) => ({
            ...prev,
            email: `The email you entered is already registered.`,
          }));
      } else return toast.error(res.message);
    }
  };

  const handleComplete = async (newCode: string) => {
    const { valid, confirm_code } = await otpValidation({
      type: "email",
      verifyData: email ?? "",
      data: { confirm_code: newCode } as userInfoProps,
    });
    if (!valid)
      return setError((prev) => ({
        ...prev,
        confirm_code: confirm_code,
      }));
    startLoading();
    const res = await userService.update({
      data: { ...user, email: email },
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Account info updated successfully");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: res.data ?? {} },
      });
      onSuccess();
    } else return toast.error("Something went wrong. Please try again.");
  };

  const handleClickResend = async () => {
    getOtpVerifyCode({ next: false });
  };

  useEffect(() => {
    if (!user.id) return;
    setEmail(user?.email ?? "");
  }, [user]);

  return (
    <div>
      {step === 0 ? (
        <div className="flex flex-col gap-3">
          <CustomText className="text-lg font-semibold">Email</CustomText>
          <CustomText className="text-sm font-pRegular text-mBIcon">
            Upgrade your Email for a personalized experience
          </CustomText>
          <TextField
            error={Boolean(error?.email)}
            helperText={Boolean(error?.email) ? error?.email : ""}
            id="email"
            fullWidth={true}
            label="Email"
            value={email}
            onChange={(e: eventType) => setEmail(e.target.value)}
          />
          <Button
            fullWidth={true}
            color="primary"
            variant="contained"
            className="capitalize"
            size="large"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      ) : (
        // <VerificationOtpSection
        //   otp={confirmCode}
        //   verifyData={email}
        //   error={error}
        //   onComplete={handleComplete}
        // />
        <VerificationOtpSection
          verifyData={email}
          error={error}
          otp={confirmCode ?? ""}
          onClickResend={handleClickResend}
          onChange={(newValue: string) => {
            setConfirmCode(newValue);
            setError((prev) => ({ ...prev, confirm_code: "" }));
          }}
          onComplete={handleComplete}
        />
      )}
    </div>
  );
};

export default EditEmail;
