import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import AddressPickerComponent from "components/AddressPickerComponent";
import CustomDatePicker from "components/CustomDatePicker";
import CustomLink from "components/CustomLink";
import CustomText from "components/CustomText";
import { errorProps, eventType, userInfoProps } from "utility/types";

export const VerificationSection = ({
  error = {} as errorProps,
  data = {} as userInfoProps,
  terms = false,
  onChangeTerms = () => null,
  onChange = () => null,
}: {
  terms: boolean;
  error: errorProps;
  data: userInfoProps;
  onChangeTerms: () => void;
  onChange: (e: eventType) => void;
}) => {
  return (
    <>
      <CustomText className="font-pSemiBold" variant="h4">
        Your Information
      </CustomText>
      <Grid className="py-5" container spacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <TextField
              name="firstName"
              value={data?.firstName ?? ""}
              type="text"
              placeholder="First Name"
              fullWidth
              error={Boolean(error?.firstName)}
              helperText={error?.firstName ?? ""}
              required
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <TextField
              name="lastName"
              value={data?.lastName ?? ""}
              type="text"
              placeholder="Last Name"
              fullWidth
              required
              error={Boolean(error?.lastName)}
              helperText={error?.lastName ?? ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <FormLabel>Birthday</FormLabel>
            <CustomDatePicker
              helperText={error?.["birthday"] ?? ""}
              data={data?.birthday ?? ""}
              onChange={(v: string) =>
                onChange({
                  target: { name: "birthday", value: v },
                } as unknown as eventType)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <FormLabel>Home Address</FormLabel>
            <AddressPickerComponent
              error={error}
              value={data?.address ?? null}
              placeholder="Your Address"
              onChange={(value) =>
                onChange({
                  target: { name: "address", value: value },
                } as unknown as eventType)
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className="flex items-start pt-6 pb-2">
        <FormControl error={Boolean(error?.terms)}>
          <div className="items-start flex">
            <CustomText className="text-grayBlack font-pRegular">
              <Checkbox
                className="p-0 pr-2"
                checked={terms}
                onChange={onChangeTerms}
              />
              By continuing, I agree to Timata’s{" "}
              <CustomLink
                href="https://www.timata.nz/terms-of-service"
                target="_blank"
                className="text-primary underline"
              >
                Terms of Service
              </CustomLink>{" "}
              and acknowledge Timata’s{" "}
              <CustomLink
                href="https://www.timata.nz/privacy-policy"
                target="_blank"
                className="text-primary underline"
              >
                Privacy Policy
              </CustomLink>{" "}
            </CustomText>
          </div>
          <FormHelperText>{error?.["terms"] ?? ""}</FormHelperText>
        </FormControl>
      </div>
    </>
  );
};
