import {
  AddCircle,
  ChevronRight,
  Close,
  KeyboardBackspace,
} from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import USER_AVATAR from "assets/img/user.png";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers";
import ChangePassword from "./ChangePassword";
import EditAddress from "./EditAdress";
import EditEmail from "./EditEmail";
import EditName from "./EditName";
import EditPhone from "./EditPhone";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AccountEdit = () => {
  const router = useNavigate();
  const { state } = useLocation();
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const [value, setValue] = useState(0);
  const [disPage, setDisPage] = useState("general");
  const [secPageOpt, setSecPageOpt] = useState("delete");
  // const [newUserData, setNewUserData] = useState<IUserProfile>(user);

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const AccountItems = [
    {
      label: "Name",
      content: `${user?.firstName} ${user?.lastName}`,
      disPage: "name",
    },
    {
      label: "Phone Number",
      content: user?.phone ? `+${user?.phoneCode} ${user?.phone}` : "Not yet",
      disPage: "phone",
    },
    { label: "Email", content: user?.email ?? "", disPage: "email" },
    {
      label: "Address",
      content: user?.address.label ?? "",
      disPage: "address",
    },
  ];

  // const handleChange = (e: eventType) => {
  //   const updatedData = { ...newUserData, [e.target.id]: e.target.value };
  //   setNewUserData(updatedData);
  // };

  const AccountItem = ({
    label = "",
    content = "",
    disPage = "name",
  }: {
    label: string;
    content: string;
    disPage: string;
  }) => {
    return (
      <div className="flex flex-col gap-2" onClick={() => setDisPage(disPage)}>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <CustomText className="font-pMedium">{label}</CustomText>
            <CustomText className="text-mBIcon line-clamp-1">
              {content}
            </CustomText>
          </div>
          <ChevronRight />
        </div>
        <hr />
      </div>
    );
  };

  const General = () => {
    return (
      <div className="flex flex-col gap-4">
        <div className="relative p-4 rounded-full bg-bgColor w-20 border">
          <CustomImage
            src={user?.avatar ? user?.avatar : USER_AVATAR}
            alt=""
          ></CustomImage>
          <AddCircle
            color="primary"
            className=" rounded-full absolute bottom-0 right-0"
          />
        </div>
        <CustomText className="text-mBIcon">Basic info</CustomText>
        <div className="flex flex-col gap-4">
          {AccountItems.map((item, index) => (
            <AccountItem key={index} {...item} />
          ))}
        </div>
      </div>
    );
  };

  const SelPage = () => {
    switch (disPage) {
      case "general":
        return <General />;

      case "name":
        return <EditName user={user} onSuccess={() => setDisPage("general")} />;

      case "phone":
        return (
          <EditPhone user={user} onSuccess={() => setDisPage("general")} />
        );

      case "email":
        return (
          <EditEmail user={user} onSuccess={() => setDisPage("general")} />
        );

      case "address":
        return (
          <EditAddress
            user={user}
            // onChange={({ address }) =>
            //   setNewUserData((prev) => ({ ...prev, ...address }))
            // }
            onSuccess={() => setDisPage("general")}
          />
        );

      default:
        return <General />;
    }
  };

  return (
    <Layout>
      <MobilePaper className="px-4 py-6 flex flex-col gap-4">
        <div
          className="flex gap-2"
          onClick={() => {
            disPage === "general" ? router(-1) : setDisPage("general");
          }}
        >
          <KeyboardBackspace />
          <CustomText>Back</CustomText>
        </div>
        <Box>
          <Tabs
            value={value}
            onChange={(e, v) => setValue(v)}
            className="border-b"
          >
            <Tab
              label="Account Info"
              {...a11yProps(0)}
              className="capitalize font-pSemiBold"
            />
            <Tab
              label="Security"
              {...a11yProps(1)}
              className="capitalize font-pSemiBold"
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SelPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {secPageOpt === "delete" ? (
            <div>
              <div
                className="flex flex-col gap-6"
                onClick={() => {
                  setSecPageOpt("psdChange");
                  setDisPage("psdChange");
                }}
              >
                <CustomText className="text-mBIcon">
                  Logging in to Timata
                </CustomText>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <CustomText className="font-pMedium">Password</CustomText>
                    <CustomText className="text-mBIcon">********</CustomText>
                  </div>
                  <ChevronRight />
                </div>
                <hr className="w-full" />
              </div>
              <CustomText
                className="flex items-center font-pMedium text-red-500 absolute bottom-12 left-3"
                onClick={() => {
                  router("/account/delete", {
                    state: { ...state, pathName: "account" },
                  });
                }}
              >
                <Close />
                Delete Timata Account
              </CustomText>
            </div>
          ) : (
            <ChangePassword />
          )}
        </TabPanel>
      </MobilePaper>
    </Layout>
  );
};

export default AccountEdit;
