import { History } from "history";
import { combineReducers } from "redux";
import { IOrderS, IUserS } from "redux/model";
import { IMealS } from "utility/types";
import * as loadingReducer from "./loading";
import * as orderReducer from "./order";
import * as userProfileReducer from "./userProfile";
import * as mealReducer from "./mealReducer";

export interface RootState {
  isLoading: boolean;
  userProfile: IUserS;
  order: IOrderS;
  meal: IMealS;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (history: History) => {
  return combineReducers({
    ...userProfileReducer,
    ...loadingReducer,
    ...orderReducer,
    ...mealReducer,
  });
};
