import { Close } from "@mui/icons-material";
import MASTERCARD_IMG from "assets/img/card/mastercard.png";
import VISA_IMG from "assets/img/card/visa.svg";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { RootState } from "redux/reducers";
import userService from "services/user.service";
import { APIResponseCode } from "utility/types";

const EditPayment = () => {
  const { state } = useLocation();
  const router = useNavigate();
  const user = useSelector((state: RootState) => state.userProfile.user);
  const card = state?.card ?? {};
  console.log({ card });

  const handleRemove = async () => {
    if (Boolean(user.paymentMethod === card?.id))
      return toast.warning("You must change the default card.");
    startLoading();
    const res = await userService.detachPaymentCard({
      userId: user.id,
      paymentMethodId: card?.id,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success("Card detached successfully.");
      router(-1);
    } else return toast.warning(res.message);
  };

  return (
    <Layout>
      <MobilePaper className="px-4 py-6 flex flex-col gap-4">
        <BackTitle title="Edit Payment Method" url="" />
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <div>
              <CustomText className="font-pSemiBold">
                {card?.card?.brand}
              </CustomText>
              <CustomText className="text-mBIcon">
                ****{card?.card?.last4}
              </CustomText>
            </div>
            <CustomImage
              src={
                card?.card?.brand ?? "visa" === "visa"
                  ? VISA_IMG
                  : MASTERCARD_IMG
              }
              alt=""
              height={20}
              width={"auto"}
            />
          </div>
          <div className="flex flex-col gap-3">
            <CustomText>Expiry</CustomText>
            <CustomText>
              {card?.card?.exp_month} / {card?.card?.exp_year}
            </CustomText>
          </div>
          <hr />
        </div>
        <div className="flex gap-2 text-red-600 cursor-pointer" onClick={handleRemove}>
          <Close />
          <CustomText color="red-600">
            Remove payment method
          </CustomText>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default EditPayment;
