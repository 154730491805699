import AccountEdit from "pages/account/AccountEdit";
import AddCredit from "pages/account/AddCredit";
import EditPayment from "pages/account/EditPayment";
import OpenAccount from "pages/account/OpenAccount";
import PaymentMethods from "pages/account/PaymentMethods";
import PrivateRoute from "./PrivateRoute";
import AddedCredit from "pages/account/AddedCredit";
import DeleteAccount from "pages/account/DeleteAccount";
import OpenFromAdmin from "pages/account/openFromAdmin";
import PublicRoute from "routes/PublicRoute";

const prefix = "/account";

const routes = [
  {
    path: `${prefix}`,
    element: (
      <PrivateRoute>
        <OpenAccount />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/edit`,
    element: (
      <PrivateRoute>
        <AccountEdit />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/payment`,
    element: (
      <PrivateRoute>
        <PaymentMethods />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/payment/edit`,
    element: (
      <PrivateRoute>
        <EditPayment />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/payment/add_credit`,
    element: (
      <PrivateRoute>
        <AddCredit />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/payment/added_credit`,
    element: (
      <PrivateRoute>
        <AddedCredit />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/delete`,
    element: (
      <PrivateRoute>
        <DeleteAccount />
      </PrivateRoute>
    ),
  },
  {
    path: `${prefix}/:uuid`,
    element: <OpenFromAdmin />,
  },
];

export default routes;
