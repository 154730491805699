import { createTheme, type ThemeOptions } from '@mui/material/styles'
import { PRIMARY_COLOR, WHITE_COLOR } from './varialble'

const lightTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: PRIMARY_COLOR,
      contrastText: WHITE_COLOR
    }
  },
  typography: {
    fontFamily: 'Poppins-Regular',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins-Light',
          color: '#000'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000'
        }
      }
    }
  }
})

export default lightTheme
