import CustomText from "components/CustomText";
import { errorProps, eventType } from "utility/types";
import { Button, FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PasswordSection = ({
  error = {} as errorProps,
  password = "",
  onChange = () => null,
  onClick = () => null,
  onLoginCode = () => null,
}: {
  error: errorProps;
  password: string;
  onChange: (e: eventType) => void;
  onClick: () => void;
  onLoginCode: () => void;
}) => {
  const router = useNavigate();

  const handleClickForgot = () => {
    router("/forgot-password");
  };

  return (
    <div className="pb-8">
      <div>
        <CustomText className="font-pSemiBold" variant="h4">
          Password
        </CustomText>
        <CustomText className="leading-6 py-2 text-lg text-grayBlack">
          Enter your password
        </CustomText>
      </div>
      <FormControl className="py-2" fullWidth>
        <TextField
          name="password"
          value={password}
          type="password"
          placeholder="******"
          fullWidth
          onChange={onChange}
          error={Boolean(error?.password)}
          helperText={error?.password}
        />
      </FormControl>
      <CustomText
        className="text-right underline cursor-pointer font-pMedium text-lightGray float-right pb-3"
        onClick={handleClickForgot}
      >
        Forgot Password?
      </CustomText>
      <Button
        className="block w-full my-8 p-3"
        variant="contained"
        size="large"
        onClick={onClick}
      >
        Login
      </Button>
      <CustomText
        className="text-center cursor-pointer text-primary"
        onClick={onLoginCode}
      >
        Send me a Login Code
      </CustomText>
    </div>
  );
};

export default PasswordSection;
