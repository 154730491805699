import { Button, TextField } from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IUserProfile, UserProfileActions } from "redux/model";
import userService from "services/user.service";
import { APIResponseCode, eventType } from "utility/types";

interface IName {
  firstName: string;
  lastName: string;
}

const EditName = ({
  user = {} as IUserProfile,
  onSuccess = () => {},
}: {
  user: IUserProfile;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<IName>({} as IName);

  const handleUpdate = async () => {
    const validationFields: (keyof IName)[] = ["firstName", "lastName"];
    //check validation user first name and last name, address
    const checkValidation =
      validationFields
        .map((key) => Boolean(userInfo[key]))
        .filter((item) => !item)?.length > 0
        ? true
        : false;
    if (checkValidation) return;
    startLoading();
    const res = await userService.update({
      data: { ...user, ...userInfo },
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      toast.success(res.message ?? "Account info updated successfully");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: res.data ?? {} },
      });
      onSuccess();
    } else
      return toast.error(
        res?.message ?? "Something went wrong. Please try again."
      );
  };

  useEffect(() => {
    setUserInfo(() => ({ firstName: user.firstName, lastName: user.lastName }));
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <CustomText className="font-pSemiBold text-lg">Name</CustomText>
        <CustomText className="text-mBIcon text-sm">
          What name would you like others to use when addressing you?
        </CustomText>
      </div>
      <TextField
        label="First Name"
        variant="outlined"
        id="firstName"
        value={userInfo?.firstName ?? ""}
        placeholder="Your First Name..."
        onChange={(e: eventType) =>
          setUserInfo((s) => {
            return { ...s, firstName: e.target.value };
          })
        }
      />
      <TextField
        label="Last Name"
        variant="outlined"
        value={userInfo?.lastName ?? ""}
        placeholder="Your Last Name..."
        onChange={(e: eventType) =>
          setUserInfo((s) => {
            return { ...s, lastName: e.target.value };
          })
        }
      />
      <Button
        fullWidth={true}
        size="large"
        color="primary"
        variant="contained"
        className="capitalize"
        onClick={handleUpdate}
      >
        update
      </Button>
    </div>
  );
};

export default EditName;
