import { ArrowForwardOutlined } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Paper } from "@mui/material";
import NEW_ORDER_IMG from "assets/img/logo/new-order.png";
import VIEW_MEAL_IMG from "assets/img/logo/view-meal.png";
import WELCOME_LOGO from "assets/img/logo/welcomelogo.png";
import CustomImage from "components/CustomImage";
import CustomLink from "components/CustomLink";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import OrderComponent from "components/OrderComponent";
import Layout from "layouts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IOrder, OrderActions } from "redux/model";
import { RootState } from "redux/reducers";
import orderService from "services/order.service";
import { APIResponseCode } from "utility/types";
import PACK_IMG from "assets/img/svg/package.svg";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );

  const loadUserOrders = async () => {
    startLoading();
    const res = await orderService.gets({ userId: user.id, delivered: false });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) setOrders(res.data);
    else toast.warning(res.message);
  };

  useEffect(() => {
    dispatch({ type: OrderActions.SET_ORDER, payload: {} });
    loadUserOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const userAgent = navigator.userAgent.toLowerCase();
  // console.log(
  //   /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
  //     userAgent
  //   ),
  //   navigator
  // );
  return (
    <Layout>
      <div className="pb-6 px-4">
        <CustomText className="text-base md:text-lg font-pMedium">
          TIMATA MEALS
        </CustomText>
        <CustomText className="underline text-[32px] font-bold font-pMedium decoration-primary">
          Kia ora, {user.firstName}
        </CustomText>
      </div>
      <MobilePaper className="mt-14">
        <div className="flex flex-col h-full">
          <div className="welcome">
            <Box
              className="relative overflow-hidden bg-primary rounded mx-4 p-4 cursor-pointer"
              onClick={() => router("/welcome")}
            >
              <CustomText className="font-pBold text-xl md:text-3xl text-white">
                Welcome!
              </CustomText>
              <CustomText className="font-pSemiBold text-sm md:text-xl text-white">
                Learn how Timata Meals work.
              </CustomText>
              <CustomLink href="/dashboard" className="relative z-10">
                <CustomText className="text-white text-sm md:text-lg flex items-center justify-end cursor-pointer">
                  Learn More
                  <ArrowForwardOutlined className="pl-2" fontSize="large" />
                </CustomText>
              </CustomLink>
              <CustomImage
                src={WELCOME_LOGO}
                alt=""
                className="absolute h-[165px] top-0 right-[-20%]"
                width={"auto"}
              />
            </Box>
          </div>
          <div className="px-4 pt-14 flex flex-col h-full">
            <Grid className="py-5" container spacing={2} rowSpacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <Paper
                  className="bg-bgColor p-4 h-44 flex flex-col justify-between relative overflow-hidden cursor-pointer"
                  onClick={() => router("/order/add")}
                >
                  <CustomText className="font-pBold text-xl">
                    Place New <br />
                    Order
                  </CustomText>
                  <IconButton className="bg-primary p-1 rounded w-fit">
                    <ArrowForwardOutlined
                      className="text-white"
                      fontSize="large"
                    />
                  </IconButton>
                  <CustomImage
                    src={NEW_ORDER_IMG}
                    alt=""
                    className="absolute -bottom-6 -right-8 max-w-[150px]"
                    width={"70%"}
                    height={"auto"}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Paper
                  className="bg-bgColor p-4 h-44 flex flex-col justify-between relative overflow-hidden cursor-pointer"
                  onClick={() => router("/meals")}
                >
                  <CustomText className="font-pBold text-xl">
                    View Our <br />
                    Meals
                  </CustomText>
                  <IconButton className="bg-primary p-1 rounded w-fit">
                    <ArrowForwardOutlined
                      className="text-white"
                      fontSize="large"
                    />
                  </IconButton>
                  <CustomImage
                    src={VIEW_MEAL_IMG}
                    alt=""
                    className="absolute -bottom-6 -right-8 max-w-[150px]"
                    width={"70%"}
                    height={"auto"}
                  />
                </Paper>
              </Grid>
            </Grid>
            <div className="flex justify-between items-center">
              <CustomText className="font-pSemiBold text-lg pb-2">
                My Orders
              </CustomText>
              <CustomText
                className="text-mBIcon text-sm font-pRegular"
                onClick={() =>
                  router("/activity", {
                    state: { ...state, pathName: "activity" },
                  })
                }
              >
                View All
              </CustomText>
            </div>
            <div className="flex flex-col flex-1 basis-0 overflow-y-auto">
              {orders?.length > 0 ? (
                orders.map((order, index) => (
                  <OrderComponent order={order} key={index} />
                ))
              ) : (
                <>
                  <div className="py-6 bg-bgColor rounded-t-md flex flex-col items-center justify-center gap-2 flex-1 basis-0 overflow-y-auto">
                    <CustomImage src={PACK_IMG} alt="" height={50} width={50} />
                    <CustomText className="text-mBIcon">
                      Your future orders will be displayed here
                    </CustomText>
                  </div>
                  <Button
                    color="primary"
                    fullWidth={true}
                    variant="contained"
                    size="large"
                    className="rounded-t-none normal-case text-lg font-pSemiBold"
                    onClick={() => router("/order/add")}
                  >
                    Start a New Order
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default Dashboard;
