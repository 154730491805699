import { KeyboardBackspace } from "@mui/icons-material"
import CustomImage from "components/CustomImage"
import CustomText from "components/CustomText"
import MobilePaper from "components/MobilePaper"
import Layout from "layouts"
import { useEffect, useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { finishLoading, startLoading } from "redux/action"
import mealsServices from "services/meals.services"
import { APIResponseCode, Meal } from "utility/types"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@mui/material"

const MealsDetail = () => {
  const router = useNavigate();
  const [meals, setMeals] = useState<Meal[]>([]);

  const loadMeals = async () => {
    startLoading();
    const res = await mealsServices.gets();
    finishLoading();

    if (res.code === APIResponseCode.SUCCESS) setMeals(res.data);
    else toast.warning(res.message);
  }

  const CustomArrow = ({ onClick = () => { }, label = "", className = "" }) => {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        size="large"
        className={`absolute bottom-0 ${className} z-[1]`}
      >
        {label}
      </Button>
    );
  };

  const renderPrevArrow = (onClickHandler, hasPrev, label) => {
    return (
      <CustomArrow
        onClick={onClickHandler}
        className="left-0"
        label="Prev"
      />
    );
  };

  const renderNextArrow = (onClickHandler, hasNext, label) => {
    return (
      <CustomArrow
        onClick={onClickHandler}
        className="right-0"
        label="Next"
      />
    );
  };

  useEffect(() => {
    loadMeals();
  }, []);
  return (
    <Layout maxWidth="xs">
      <MobilePaper className="p-4 py-6 flex flex-col">
        <div className="flex flex-col gap-2">
          <div className="flex items-center" onClick={() => router(-1)}>
            <KeyboardBackspace />
            <CustomText className="font-pRegular ml-1">
              Back
            </CustomText>
          </div>
          {meals?.length > 0
            ? (<Carousel
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
              renderArrowPrev={renderPrevArrow}
              renderArrowNext={renderNextArrow}
            // autoPlay={true}
            // interval={5000}
            >
              {meals.map((meal, index) => (
                <div key={index} className="flex flex-col gap-4">
                  <div className="flex flex-col items-center gap-4">
                    <CustomImage src={meal.image} alt="" className="h-64 w-auto" />
                    <CustomText className="font-pSemiBold text-2xl font-semibold">{meal.name}</CustomText>
                  </div>
                  <div className="text-left h-72">
                    <CustomText className="font-pRegular text-sm h-16">{meal.subTitle}</CustomText>
                    <CustomText className="p-4 bg-bgColor rounded h-40">{meal.summary}</CustomText>
                  </div>
                </div>
              ))}
            </Carousel>)
            : null}
        </div>
      </MobilePaper>
    </Layout>
  )
}

export default MealsDetail