import { ArrowBackOutlined } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import CountButton from "components/CountButton";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderActions } from "redux/model";
import { RootState } from "redux/reducers";
import { removeArray, updateArray } from "utility/helper";
import { addMinus } from "utility/types";

const OrderDetail = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [selCount, setSelCount] = useState<number>(0);
  const [selIndex, seSelIndex] = useState<number>(-1);
  const orderP = useSelector((state: RootState) => state?.order ?? {});

  const nextDisable = useMemo(() => {
    const { count = 6, meals = [] } = orderP?.order ?? {};
    const totalCount = (
      selIndex < 0 ? meals : removeArray(meals, selIndex)
    ).reduce((ret, cur) => ret + cur?.count ?? 0, 0);
    return totalCount + selCount === count;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selCount]);

  const handleAddMeal = (type: addMinus) => {
    setSelCount((prev) => (type === "plus" ? prev + 1 : prev - 1));
  };

  const selecMeal = () => {
    let meals = orderP?.order?.meals ?? [];
    //check the meals and count plus or minus in the order meals
    meals =
      selIndex < 0
        ? [...meals, { meal: orderP.selMeal, count: selCount }]
        : selCount === 0
        ? removeArray(meals, selIndex)
        : updateArray(meals, selIndex, {
            meal: orderP.selMeal,
            count: selCount,
          });
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: { ...(orderP?.order ?? {}), meals: meals } },
    });
    router(-1);
  };

  useEffect(() => {
    const { order, selMeal } = orderP;
    const selIndex = (order?.meals ?? []).findIndex(
      (item) => item?.meal?.id === selMeal?.id
    );
    const selMealCount =
      (order?.meals ?? []).find((item) => item?.meal?.id === selMeal?.id)
        ?.count ?? 0;
    setSelCount(selMealCount);
    seSelIndex(selIndex);
  }, [orderP]);

  return (
    <Layout maxWidth="xs">
      <MobilePaper className="p-4 py-6 flex flex-col">
        <div className="flex items-center">
          <IconButton onClick={() => router(-1)}>
            <ArrowBackOutlined />
          </IconButton>
          <CustomText>Back</CustomText>
        </div>
        <div className="flex-1 justify-between flex flex-col">
          <div>
            <div className="w-52 h-auto mx-auto p-4">
              <CustomImage
                className="h-full w-full object-cover"
                src={orderP?.selMeal?.image ?? ""}
                alt="meal Image"
              />
            </div>
            <CustomText className="font-pSemiBold text-2xl">
              {orderP?.selMeal?.name ?? ""}
            </CustomText>
            <CustomText className="font-pRegular text-grayBlack py-2">
              {orderP?.selMeal?.subTitle ?? ""}
            </CustomText>
            <div className="py-3">
              <CountButton
                nextDisable={!nextDisable}
                count={selCount}
                onClick={handleAddMeal}
              />
            </div>
            <div className="bg-bgColor rounded-md p-4">
              <CustomText className="font-pRegular">
                {orderP?.selMeal?.summary ?? ""}
              </CustomText>
            </div>
          </div>
          <Button variant="contained" size="large" onClick={selecMeal}>
            Select
          </Button>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default OrderDetail;
