import { Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CountButton from "components/CountButton";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IOrder } from "redux/model";
import mealsServices from "services/meals.services";
import { addMinus, APIResponseCode, Meal } from "utility/types";

const SelectMeals = ({
  data = {} as IOrder,
  onAddMeal = () => {},
  onNavigate = () => {},
  onNext = () => {},
}: {
  data: IOrder;
  onAddMeal: (meal: Meal, type: addMinus) => void;
  onNavigate: (meal: Meal) => void;
  onNext: () => void;
}) => {
  const [meals, setMeals] = useState<Meal[]>([]);
  const selMealCount = (data?.meals ?? []).reduce(
    (ret, cur) => ret + cur?.count ?? 0,
    0
  );

  const loadMeals = async () => {
    const res = await mealsServices.gets();
    if (res.code === APIResponseCode.SUCCESS) setMeals(res.data);
    else toast.warning(res.message);
  };

  useEffect(() => {
    loadMeals();
  }, []);

  return (
    <div className="flex-1 flex flex-col justify-between">
      <div>
        <CustomText className="font-pSemiBold text-xl">
          Select Your Meals
        </CustomText>
        <CustomText className="font-pSemiBold pb-3 text-grayBlack">
          {selMealCount} of {data.count} Selected
        </CustomText>
      </div>
      <div className="flex-1 basis-0 overflow-y-auto flex flex-col gap-2">
        {meals.map((meal, index) => {
          const count =
            (data?.meals ?? []).find(
              (item) => item?.meal?.id === meal?.id ?? ""
            )?.count ?? 0;
          return (
            <Grid2 className="bg-bgColor rounded-md py-3" container key={index}>
              <Grid2 xs={4} sm={4} md={3}>
                <CustomImage
                  className="px-2"
                  src={meal?.image ?? ""}
                  alt="meal image"
                  onClick={() => onNavigate(meal)}
                />
              </Grid2>
              <Grid2 className="px-2" xs={8} sm={8} md={9}>
                <div onClick={() => onNavigate(meal)}>
                  <CustomText className="font-pSemiBold">
                    {meal.name}
                  </CustomText>
                  <CustomText className="text-xs font-pRegular pb-2 text-grayBlack">
                    {meal.subTitle}
                  </CustomText>
                </div>
                <CountButton
                  nextDisable={Boolean(data.count !== selMealCount)}
                  count={count}
                  onClick={(type) => onAddMeal(meal, type)}
                />
              </Grid2>
            </Grid2>
          );
        })}
      </div>
      <div>
        <Button
          className="my-2"
          variant="contained"
          disabled={Boolean(data.count !== selMealCount)}
          size="large"
          fullWidth
          onClick={onNext}
        >
          {data.count === selMealCount
            ? `Continue with Selected Meals`
            : `Choose ${data.count} More Meals`}
        </Button>
      </div>
    </div>
  );
};

export default SelectMeals;
