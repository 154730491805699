import Activity from "pages/activity";
import ActivityDetail from "pages/activity/activityDetail";
import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    path: "/activity",
    element: (
      <PrivateRoute>
        <Activity />
      </PrivateRoute>
    ),
  },
  {
    path: "/activity/:id",
    element: (
      <PrivateRoute>
        <ActivityDetail />
      </PrivateRoute>
    ),
  },
];

export default routes;
