import {
  BorderColorOutlined,
  ChevronRight,
  ListAlt,
  Logout,
  Payment,
} from "@mui/icons-material";
import USER_AVATAR from "assets/img/user.png";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderActions } from "redux/model";
import { RootState } from "redux/reducers";
import { INTERCOM_ID } from "utility";

const ProfileItem = ({
  children = <></>,
  label = "",
  link = "",
  pathName = "",
}: {
  children: JSX.Element;
  label: string;
  link: string;
  pathName?: string;
}) => {
  const { state = {} } = useLocation();
  const router = useNavigate();
  return (
    <div
      className="flex justify-between items-center cursor-pointer hover:shadow-md p-2 rounded"
      onClick={() => router(link, { state: { ...state, pathName: pathName } })}
    >
      <div className="flex gap-2 items-center">
        {children}
        <CustomText className="capitalize font-pSemiBold text-lg">
          {label}
        </CustomText>
      </div>
      <ChevronRight />
    </div>
  );
};

const profileItems = [
  {
    children: <BorderColorOutlined />,
    label: "edit profile",
    link: "/account/edit",
    pathName: "account",
  },
  {
    children: <ListAlt />,
    label: "previous orders",
    link: "/activity",
    pathName: "activity",
  },
  {
    children: <Payment />,
    label: "payment methods",
    link: "/account/payment",
    pathName: "account",
  },
];

const OpenAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const router = useNavigate();

  const handleLogout = () => {
    dispatch({ type: OrderActions.SET_ORDER, payload: {} });
    (window as any).Intercom("shutdown");
    (window as any).Intercom("boot", {
      app_id: INTERCOM_ID,
    });

    router("/logout");
  };

  useEffect(() => {
    (window as any).Intercom("boot", {
      app_id: INTERCOM_ID,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.phone ? `+${user.phoneCode}${user.phone}` : "",
      user_id: user.id,
      created_at: user?.created_at,
    });
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    return () => {
      (window as any).Intercom("shutdown");
    };
  }, []);

  return (
    <Layout>
      <MobilePaper className="px-4 py-6 flex flex-col gap-4 relative">
        <BackTitle title="Profile" url="" />
        <div className="flex flex-col gap-4 flex-1 basis-0 overflow-y-auto">
          <div className="flex items-center gap-4">
            <div className="p-4 bg-bgColor border rounded-full">
              {user.avatar ? (
                <CustomImage src={user.avatar} alt="" />
              ) : (
                <CustomImage src="" alt="" />
              )}
              <CustomImage src={USER_AVATAR} alt="" />
            </div>
            <div className="flex flex-col gap-2">
              <CustomText className="text-mBIcon text-sm">{`${user.firstName} ${user.lastName}`}</CustomText>
              <CustomText className="text-mBIcon text-sm">
                {user.phone
                  ? `+${user?.phoneCode ?? ""}${user.phone}`
                  : "Not yet (Phone Number)"}
              </CustomText>
              <CustomText className="text-mBIcon text-sm">
                {user.email}
              </CustomText>
            </div>
          </div>
          <hr />
          <div className="flex flex-col gap-3 mt-3">
            {profileItems.map((item, index) => (
              <ProfileItem key={index} {...item}>
                {item.children}
              </ProfileItem>
            ))}
          </div>
          <div className="flex items-center gap-2" onClick={handleLogout}>
            <Logout fontSize="large" />
            <CustomText className="text-lg font-pSemiBold" onClick={handleLogout}>
              Logout
            </CustomText>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default OpenAccount;
