import { Container, Paper } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import HtmlContainer from "./HtmlContainer";

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HtmlContainer>
      <Container className="flex flex-1 flex-col" maxWidth="xs" disableGutters>
        <Paper className="flex flex-1 flex-col rounded-none" elevation={2}>
          {children}
        </Paper>
      </Container>
    </HtmlContainer>
  );
};

export default AuthLayout;
