import { IUserProfile, IUserS } from "redux/model";
import { apiPath } from "utility/apiPaths";
import axios from "utility/axios";
import { APIResponseCode, APIResponseType, userInfoProps } from "utility/types";
import { APIService } from "./api.service";

class UserService extends APIService<IUserProfile> {
  async gets(): Promise<APIResponseType<IUserProfile[]>> {
    try {
      const { data } = await axios.get(apiPath.GET_MEALS);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IUserProfile[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [],
        message: "Network Connection Problem",
      };
    }
  }

  async login({
    verifyData,
    password,
  }: {
    verifyData: string;
    password: string;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.post(apiPath.USER_LOGIN, {
        verifyData,
        password,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async loginCode({
    verifyData,
    password,
  }: {
    verifyData: string;
    password: string;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.post(apiPath.USER_LOGIN_WITH_CODE, {
        verifyData,
        password,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async register({
    data: userData = {} as userInfoProps,
    verifyData,
    type,
    phoneCode,
  }: {
    data: userInfoProps;
    verifyData: string;
    type: string;
    phoneCode?: string;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const snsData =
        type === "email"
          ? {
              email: verifyData.toLocaleLowerCase(),
              phone: userData?.phone ?? "",
            }
          : {
              phone: verifyData,
              phoneCode: phoneCode,
              email: (userData?.email ?? "").toLocaleLowerCase(),
            };
      const userInfo = {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        confirm_code: userData?.confirm_code,
        address: userData?.address,
        birthday: userData?.birthday,
        password: userData?.password,
        ...snsData,
      };
      const { data } = await axios.post(apiPath.USER_REGISTER, userInfo);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IUserS>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: "Network Connection Problem",
      };
    }
  }

  async forgotPassword({
    verifyData,
    data: userData = {} as IUserProfile,
  }: {
    verifyData: string;
    data: IUserProfile;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.post(apiPath.USER_FORGOT_PASSWORD, {
        verifyData,
        data: userData,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async attachPaymentCard({
    userId = "",
    paymentMethodId = "",
  }: {
    userId: string | number;
    paymentMethodId: string;
  }): Promise<APIResponseType<string>> {
    try {
      const { data } = await axios.post(apiPath.ATTACH_PAYMENT_CARD, {
        userId,
        paymentMethodId,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<string>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: "",
        message: "Network Connection Problem",
      };
    }
  }

  async getPaymentMethods({
    customerId,
  }: {
    customerId: string;
  }): Promise<APIResponseType<any[]>> {
    try {
      const { data } = await axios.post(apiPath.PAYMENT_METHODS, {
        customerId,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<any[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [],
        message: "Network Connection Problem",
      };
    }
  }

  async sendOtp({
    type = "email",
    verifyData = "",
  }: {
    type: "email" | "phone";
    verifyData: string;
  }): Promise<APIResponseType<boolean | string>> {
    try {
      const url = type === "email" ? "otp-email" : "otp";
      const { data } = await axios.post(`${apiPath.USER_REGISTER}/${url}`, {
        type,
        verifyData,
      });
      localStorage.setItem("otpCode", data?.OTP);
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<boolean | string>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: false,
        message: "Network Connection Problem",
      };
    }
  }

  async verifyOtp({
    otp = "",
    type = "email",
    verifyData = "",
  }: {
    otp: string;
    type: "email" | "phone";
    verifyData: string;
  }): Promise<APIResponseType<boolean>> {
    try {
      const { data } = await axios.post(apiPath.USER_VERIFY_OTP_TIWILIO, {
        type,
        verifyData,
        otp,
      });
      return {
        data: data.success,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<boolean>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: false,
        message: "Network Connection Problem",
      };
    }
  }

  async accountValidation({
    type = "email",
    verifyData = "",
    phoneCode = "+64",
  }: {
    type?: "email" | "phone";
    verifyData: string;
    phoneCode?: string;
  }): Promise<APIResponseType<boolean>> {
    try {
      const { data } = await axios.post(apiPath.USER_ACCCOUNT_VALIDATIOIN, {
        verifyData: verifyData,
        type,
        phoneCode,
      });
      return {
        data: data.exist,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<boolean>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: false,
        message: "Network Connection Problem",
      };
    }
  }

  async checkPromoCode({
    promocode,
    userId,
  }: {
    userId: string;
    promocode: string;
  }): Promise<
    APIResponseType<{
      exist: boolean;
      balance?: number;
      redeemed: number;
      message?: string;
    }>
  > {
    try {
      const { data } = await axios.post(apiPath.CHECK_PROMOCODE, {
        promocode,
        userId,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<{
        exist: boolean;
        balance?: number;
        redeemed: number;
      }>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: { exist: false, redeemed: 0 },
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async adminLogin({ id }: { id: string }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.get(`${apiPath.USER_ADMIN_LOGIN}/${id}`);
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      console.log(error);
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async resetPassword({
    verifyData,
    data: userData = {} as IUserProfile,
  }: {
    verifyData: any;
    data: IUserProfile;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.post(apiPath.USER_RESET_PASSWORD, {
        verifyData,
        data: userData,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      console.log(error);
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async update({
    data: userData = {} as IUserProfile,
    password = false,
    confirmPassword = "",
    id = 1,
  }: {
    data: IUserProfile;
    password?: boolean;
    confirmPassword?: string;
    id?: number | string;
  }): Promise<APIResponseType<IUserS>> {
    try {
      const { data } = await axios.post(apiPath.USER_UPDATE, {
        ...userData,
        password,
        confirmPassword,
        id,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<IUserS>;
    } catch (error: any) {
      console.log(error, "eror");
      return {
        code: APIResponseCode.FAILED,
        data: {} as IUserS,
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }

  async detachPaymentCard({
    userId = "",
    paymentMethodId = "",
  }: {
    userId: string | number;
    paymentMethodId: string;
  }): Promise<APIResponseType<string>> {
    try {
      const { data } = await axios.post(apiPath.DETACH_PAYMENT_CARD, {
        userId,
        paymentMethodId,
      });
      return {
        data: data,
        code: APIResponseCode.SUCCESS,
      } as APIResponseType<string>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: "",
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
