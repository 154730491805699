import { LoadingActions, LoadingActionTypes } from "redux/model";
import createReducer from "./createReducer";

const INITIAL_LOADING_STATE = false;
export const isLoading = createReducer<boolean>(INITIAL_LOADING_STATE, {
  [LoadingActions.LOADING](state: {}, action: LoadingActionTypes) {
    return action.payload;
  },
  [LoadingActions.LOADED](state: {}, action: LoadingActionTypes) {
    return action.payload;
  },
});
