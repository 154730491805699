import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import ProgressBack from "components/ProgressBack";
import Layout from "layouts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IOrder,
  IUserProfile,
  OrderActions,
  addressRightProps,
} from "redux/model";
import { RootState } from "redux/reducers";
import { removeArray, updateArray } from "utility/helper";
import { Meal, addMinus } from "utility/types";
import DeliveryAddress, { IOnChangeAddress } from "./DeliveryAddress";
import MealCount from "./MealCount";
import SelectMeals from "./SelectMeals";

const AddOrder = () => {
  const router = useNavigate();
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const [nonUrban, setNonUrban] = useState<boolean>(true);
  const [data, setData] = useState<IOrder>(Object);
  const [user, setUser] = useState<IUserProfile>(Object);
  const [step, setStep] = useState<number>(0);
  // const order = useSelector((state: RootState) => state?.order ?? {});
  // const user = useSelector(
  //   (state: RootState) => state?.userProfile?.user ?? {}
  // );
  const state = useSelector((state: RootState) => state ?? {});

  const handleBack = () => {
    if (step === 0) {
      router(-1);
      return;
    }
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: data, step: step - 1 },
    });
    setStep((prev) => prev - 1 );
  };

  const handleChange = ({
    address = {} as addressRightProps,
    nonUrbanFlag = true,
  }: IOnChangeAddress) => {
    setNonUrban(nonUrbanFlag);
    setData((prev) => ({ ...prev, address }));
  };

  const handleClickNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleAddMeals = (meal: Meal, type: addMinus) => {
    let meals = data?.meals ?? [];
    const mealIndex = meals.findIndex((item) => item?.meal?.id === meal.id);
    //check the meals and count plus or minus in the order meals
    meals =
      mealIndex < 0
        ? [...meals, { meal: meal, count: 1 }]
        : type === "plus"
        ? updateArray(meals, mealIndex, {
            ...meals[mealIndex],
            count: meals[mealIndex].count + 1,
          })
        : meals[mealIndex]?.count === 1
        ? removeArray(meals, mealIndex)
        : updateArray(meals, mealIndex, {
            ...meals[mealIndex],
            count: meals[mealIndex].count - 1,
          });
    setData((prev) => ({ ...prev, meals: meals }));
  };

  const handleNavigate = (meal: Meal) => {
    dispatch({
      type: OrderActions.SET_ORDER,
      payload: { order: data, step: step, selMeal: meal },
    });
    router(`/meal/${meal.id}`);
  };

  const handleNextAddress = () => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: data, step: step },
    });
    setStep((prev) => prev + 1);
  };

  const handleNext = () => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: data, step: step },
    });
    router("/overview");
  };

  useEffect(() => {
    setData(state?.order?.order ?? {});
    setUser(state?.userProfile?.user ?? {});
    setStep((prev) => (prev ? prev : state?.order?.step ?? 0));
    console.log("state", state);
  }, [state]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      address: prev?.address?.id ? prev?.address : user?.address,
    }));
  }, [user]);

  return (
    <Layout maxWidth="xs">
      <MobilePaper className="p-4 py-6 flex flex-col">
        <div className="flex-1 flex flex-col">
          <div>
            <div className="flex justify-between items-center">
              <CustomText className="font-pSemiBold text-2xl">
                New Order
              </CustomText>
              <CustomText
                className="text-grayBlack underline cursor-pointer"
                onClick={() => router(-1)}
              >
                Cancel
              </CustomText>
            </div>
            <ProgressBack
              value={(1 / 3) * (step + 1) * 100}
              onBack={handleBack}
            />
          </div>
          {step === 0 ? (
            <DeliveryAddress
              data={data}
              onChange={handleChange}
              onNext={handleNextAddress}
              nonUrban={nonUrban}
            />
          ) : step === 1 ? (
            <MealCount
              // count={data?.count ?? 0}
              onSelectMeal={(count) =>
                setData((prev) => ({ ...prev, count: count }))
              }
              onNext={handleClickNext}
            />
          ) : step === 2 ? (
            <SelectMeals
              data={data}
              onNext={handleNext}
              onAddMeal={handleAddMeals}
              onNavigate={handleNavigate}
            />
          ) : null}
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default AddOrder;
