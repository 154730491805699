import { persistor } from "configStore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "redux-persist/lib/storage";
import { UserProfileActions } from "redux/model";

export const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const setStoreData = async () => {
    await dispatch({ type: "persist/REHYDRATE", payload: {} });
    await persistor.purge(); // make sure purge is called after dispatching REHYDRATE
    storage.removeItem("persist:timata");
    dispatch({
      type: UserProfileActions.SET_USER_PROFILE,
      payload: { user: {}, token: "" },
    });
    // localStorage.removeItem("persist:timata"); // clear localStorage manually
    // history("/");
  };
  useEffect(() => {
    setStoreData();
    history("/");
    //eslint-disable-next-line
  }, []);
  return <div></div>;
};
