import {
  HighlightOff,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import CustomImage from "./CustomImage";
import CustomText from "./CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import LOGO from "assets/img/logo/welcomelogo.png";

const TimataWallet = ({
  payType = false,
  price = 0,
  onClick = () => null,
}: {
  payType: boolean;
  price: number;
  onClick: () => void;
}) => {
  const router = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state: RootState) => state.userProfile.user);
  return (
    <div>
      <div
        className={`flex justify-between items-center px-4 py-2 bg-bgColor border rounded hover:shadow cursor-pointer ${
          payType ? "border-primary" : ""
        }`}
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          {payType ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          <div className="flex flex-col gap-1">
            <CustomText className="text-base font-pMedium">
              Timata Wallet
            </CustomText>
            <CustomText className="text-mBIcon text-sm font-pMedium">
              Current Balance ${user.balance}
            </CustomText>
            <CustomText
              className="text-sm font-pMedium underline hover:text-primary"
              onClick={() =>
                router("/account/payment/add_credit", {
                  state: { ...state, pathName: "account" },
                })
              }
            >
              Add Credit via Voucher Code
            </CustomText>
          </div>
        </div>
        <CustomImage
          src={LOGO}
          alt=""
          height={50}
          width={50}
          className="mr-3"
        />
      </div>
      {user.balance < price && payType && (
        <div className="flex items-center px-4 gap-2 mt-2">
          <HighlightOff color="error" />
          <CustomText className="text-sm font-pMedium text-mBIcon">
            You don’t have enough credit to make this purchase, but you can
            split the remaining value with a card payment.
          </CustomText>
        </div>
      )}
    </div>
  );
};

export default TimataWallet;
