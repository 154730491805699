import { Button, TextField } from "@mui/material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { UserProfileActions } from "redux/model";
import { RootState } from "redux/reducers";
import userService from "services/user.service";
import { APIResponseCode, eventType } from "utility/types";

const AddCredit = () => {
  const [voucher, setVoucher] = useState<string>("");
  const user = useSelector((state: RootState) => state.userProfile.user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const { state = {} } = useLocation();

  const handleApply = async () => {
    startLoading();
    const res = await userService.checkPromoCode({
      promocode: voucher,
      userId: user.id,
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      if (!res.data.exist) return toast.info(res.data?.message ?? "");
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: { ...user, balance: res.data.balance } },
      });
      toast.success("Voucher Code redeemed successfully");

      router("/account/payment/added_credit", {
        state: { ...state, code: voucher, amount: res.data.redeemed },
      });
      setVoucher("");
    }
  };

  return (
    <Layout>
      <MobilePaper className="px-4 py-6">
        <BackTitle title="Add Credit" url="" />
        <div className="mt-8 flex flex-col gap-4">
          <CustomText className="capitalize">credit voucher code:</CustomText>
          <div className="flex">
            <TextField
              placeholder="Enter Voucher Code"
              fullWidth={true}
              value={voucher}
              onChange={(e: eventType) => setVoucher(e.target.value)}
            />
            <Button
              color="primary"
              variant="contained"
              className="capitalize px-8"
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
          <CustomText
            className="underline text-sm cursor-pointer hover:text-primary"
            onClick={() => router(-1)}
          >
            I Don't Have a Voucher Code
          </CustomText>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default AddCredit;
