import { Button } from "@mui/material";
import Logo from "components/Logo";
import ProgressBack from "components/ProgressBack";
import AuthLayout from "layouts/AuthLayout";
import {
  emailPhoneValidation,
  otpValidation,
  passwordValidation,
} from "models/authHelper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import userService from "services/user.service";
import { isNumberString } from "utility/helper";
import {
  APIResponseCode,
  errorProps,
  eventType,
  userInfoProps,
} from "utility/types";
import { ContactSupport } from "./Login";
import { EmailPhoneSection } from "./register/EmailPhoneSection";
import { PasswordSection } from "./register/PasswordSection";
import { VerificationOtpSection } from "./register/VerificationOtpSection";

const ForgotPassword = () => {
  const router = useNavigate();
  const [error, setError] = useState<errorProps>({} as errorProps);
  const [step, setStep] = useState<number>(0);
  const [phoneCode, setPhoneCode] = useState<string>("64");
  const [type, setType] = useState<"email" | "phone">("email");
  const [verifyData, setVerifyData] = useState<string>("");
  const [data, setData] = useState<userInfoProps>({} as userInfoProps);

  const handleBack = () => {
    return step === 0 ? router("/") : setStep((prev) => prev - 1);
  };

  const handleChangeVerify = (event: eventType) => {
    const { value } = event.target;
    const typeFlag = isNumberString(value);
    if (typeFlag && value.length > 1) setType("phone");
    else setType("email");
    setVerifyData(value);
    setError((prev) => ({ ...prev, [event.target.name]: "", phoneEmail: "" }));
  };

  const handleClickResend = async () => {
    const res = await userService.sendOtp({
      type,
      verifyData: type === "email" ? verifyData : `+${phoneCode}${verifyData}`,
    });
    if (!res.data) return toast.warning(res.message);
  };

  const handleClickNext = async (newData?: userInfoProps) => {
    if (step === 0) {
      const { valid, ...message } = emailPhoneValidation({
        type,
        verifyData,
        phoneCode,
      });
      if (!valid) return setError((prev) => ({ ...prev, ...message }));
      const res = await userService.accountValidation({
        verifyData,
        phoneCode,
        type,
      });
      if (res.data) return getOtpVerifyCode();
      return setError((prev) => ({
        ...prev,
        [type]: `The ${type} you entered is not registered.`,
      }));
    }
    if (step === 1) {
      const { valid, confirm_code } = await otpValidation({
        type,
        verifyData,
        phoneCode,
        data: newData?.confirm_code ? newData : data,
      });
      if (!valid)
        return setError((prev) => ({
          ...prev,
          confirm_code: confirm_code,
        }));
      setError((prev) => ({ ...prev, confirm_code: "" }));
      setStep((prev) => prev + 1);
    }
    if (step === 2) {
      const { valid, ...message } = passwordValidation({ data });
      if (!valid)
        return setError((prev) => ({
          ...prev,
          ...message,
        }));
      setError((prev) => ({ ...prev, ...message }));
      startLoading();
      const res = await userService.forgotPassword({
        verifyData: type === "email" ? verifyData : `${phoneCode}${verifyData}`,
        data,
      });
      finishLoading();
      if (res.code === APIResponseCode.SUCCESS) {
        toast.success("Password is updated successfully");
        router("/login");
      } else toast.warning(res?.message ?? "Something went wrong");
    }
  };
  const getOtpVerifyCode = async () => {
    const res = await userService.sendOtp({
      type,
      verifyData: type === "email" ? verifyData : `+${phoneCode}${verifyData}`,
    });
    if (res.code === APIResponseCode.SUCCESS) {
      setStep((prev) => prev + 1);
    } else return toast.warning(res.message);
  };
  const handleComplete = (v: string) => {
    handleClickNext({ ...data, confirm_code: v });
  };
  return (
    <AuthLayout>
      <div className="p-10 flex flex-1 flex-col relative">
        <Logo helpIcon />
        <ProgressBack value={(1 / 4) * (step + 1) * 100} onBack={handleBack} />
        {step === 0 && (
          <EmailPhoneSection
            authType="forgot-password"
            error={error}
            type={type}
            data={verifyData}
            phoneCode={phoneCode}
            onChange={handleChangeVerify}
            onChangePhoneCode={(newCode: string) => setPhoneCode(newCode)}
          />
        )}
        {step === 1 && (
          <VerificationOtpSection
            verifyData={
              type === "email" ? verifyData : `+${phoneCode}${verifyData}`
            }
            error={error}
            otp={data?.confirm_code ?? ""}
            onClickResend={handleClickResend}
            onChange={(newValue: string) => {
              setData((prev) => ({ ...prev, confirm_code: newValue }));
              setError((prev) => ({ ...prev, confirm_code: "" }));
            }}
            onComplete={handleComplete}
          />
        )}
        {step === 2 && (
          <PasswordSection
            authType="forgot-password"
            error={error}
            data={data}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }));
              setError((prev) => ({
                ...prev,
                [e.target.name]: "",
                compare: "",
              }));
            }}
          />
        )}
        <Button
          className="block w-full"
          variant="contained"
          size="large"
          onClick={() => handleClickNext()}
        >
          {step === 0 ? "Next" : step === 1 ? "Verify" : "Set Password"}
        </Button>
        {/* {step === 0 && (
          <div className="pb-7 bottom-0 left-0 absolute w-full">
            <ContactSupport />
          </div>
        )} */}
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
