import {
  EditLocationAlt,
  KeyboardBackspaceOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import AddressPickerComponent from "components/AddressPickerComponent";
import CustomText from "components/CustomText";
import DeliveryDates from "components/DeliveryDates";
import MobilePaper from "components/MobilePaper";
import SelMealsComponent from "components/SelMealsComponent";
import Layout from "layouts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OrderActions, addressRightProps } from "redux/model";
import { RootState } from "redux/reducers";
import mealsServices from "services/meals.services";
import orderService from "services/order.service";
import { APIResponseCode, IBlackoutDay, TDeliveryDate } from "utility/types";

export const BackTitle = ({
  title = "",
  url = "",
  tClass = "",
}: {
  title: string;
  url: string;
  tClass?: string;
}) => {
  const router = useNavigate();
  return (
    <div className="flex items-center">
      <KeyboardBackspaceOutlined
        className="mr-3 cursor-pointer"
        onClick={() =>
          url
            ? router(url)
            : title === "Checkout"
            ? router("/overview")
            : router(-1)
        }
      />
      <CustomText className={tClass ? tClass : "font-pSemiBold text-2xl"}>
        {title}
      </CustomText>
    </div>
  );
};

const Overview = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [blackoutDays, setBlackoutDays] = useState<IBlackoutDay[]>([]);
  const [deliveryDates, setDeliveryDates] = useState<TDeliveryDate[]>(
    [] as TDeliveryDate[]
  );
  const orderP = useSelector((state: RootState) => state?.order ?? {});
  const selMealPrice = useSelector(
    (state: RootState) => state?.meal?.selMealPrice
  );

  const handleChangeMeals = () => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { ...orderP, step: 2 },
    });
    // router("/order/add", {
    //   state: { ...state, order: { ...orderP, step: 2 } },
    // });
    router(-1);
  };

  const handleChangeAddress = (value: addressRightProps) => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: { ...orderP.order, address: value } },
    });
    setEditFlag(false);
  };

  const handleDeliveryDate = (v: TDeliveryDate) => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: { ...orderP.order, deliveryDate: v } },
    });
  };

  const loadBlackoutDays = async () => {
    const res = await mealsServices.getBlackoutDays();
    if (res.code === APIResponseCode.SUCCESS) setBlackoutDays(res.data);
    else toast.warning(res.message);
  };

  const loadDeliveryDate = async () => {
    const res = await orderService.calcDeliveryDates(blackoutDays);
    if (res.code === APIResponseCode.SUCCESS) setDeliveryDates(res.data);
    else toast.warning(res.message);
  }

  useEffect(() => {
    loadDeliveryDate();
    // eslint-disable-next-line
  }, [blackoutDays]);

  useEffect(() => {
    loadBlackoutDays();
  }, []);

  return (
    <Layout>
      <MobilePaper className="p-4 py-6 flex flex-col gap-4">
        <BackTitle title="Overview" url="" />
        <div className="flex flex-1 flex-col justify-between basis-0 overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col gap-4">
            <CustomText className="font-pRegular text-base">
              {orderP?.order?.count} Meals Selected:
            </CustomText>
            <SelMealsComponent meals={orderP?.order?.meals} />
            <hr />
            <div className="flex justify-between items-center">
              <CustomText className="font-pRegular text-base text-mBIcon">
                Total Order:
              </CustomText>
              <div className="flex flex-col items-end">
                <CustomText className="font-pSemiBold text-primary text-xl font-bold">
                  ${selMealPrice?.totalPrice ?? "XX.XX"}
                </CustomText>
                <CustomText className="font-pRegular text-base">
                  Free Delivery
                </CustomText>
              </div>
            </div>
            <hr />
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2 w-full">
                <CustomText className="font-pRegular text-base text-mBIcon">
                  Delivery Address
                </CustomText>
                {editFlag ? (
                  <AddressPickerComponent
                    value={orderP?.order?.address ?? null}
                    placeholder="36 Grant Road, Queenstown, 9300"
                    onChange={handleChangeAddress}
                  />
                ) : (
                  <CustomText className="font-pMedium text-base line-clamp-1">
                    {orderP?.order?.address?.label ?? null}
                  </CustomText>
                )}
              </div>
              <EditLocationAlt onClick={() => setEditFlag((prev) => !prev)} />
            </div>
            <hr />
            <div className="flex flex-col gap-2">
              <CustomText className="text-base font-pMedium text-center">
                Available Delivery Date
              </CustomText>
              <DeliveryDates
                deliveryDate={orderP.order?.deliveryDate ?? {}}
                availableDays={deliveryDates}
                onClick={handleDeliveryDate}
              />
            </div>
            <Button
              variant="contained"
              size="large"
              fullWidth
              disabled={
                !Boolean(orderP.order.address?.id) ||
                !Boolean(orderP.order?.deliveryDate)
              }
              className="capitalize"
              onClick={() => router("/checkout")}
            >
              Proceed to Checkout
            </Button>
            <CustomText
              className="text-sm font-pMedium text-center underline cursor-pointer"
              onClick={handleChangeMeals}
            >
              Change Meals
            </CustomText>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default Overview;
