import { TaskAlt } from "@mui/icons-material";
import { Button, Divider, Grid } from "@mui/material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import SelMealsComponent from "components/SelMealsComponent";
import Layout from "layouts";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers";
import { MEAL_T_PRICE_6 } from "utility/variables";

export const PaymentStatus = () => {
  const { state = {} } = useLocation();
  const order = state?.order;
  const router = useNavigate();
  const price = useSelector(
    (rootState: RootState) =>
      rootState?.meal?.selMealPrice?.totalPrice ??
      state?.price ??
      MEAL_T_PRICE_6
  ) as unknown as number;
  return (
    <Layout>
      <MobilePaper className="p-4 py-6 flex flex-col justify-between">
        <div className="flex justify-between flex-col">
          <div>
            <div className="text-center">
              <TaskAlt className="text-green" fontSize="large" />
              <CustomText className="font-pBold text-2xl">
                Purchase successful!
              </CustomText>
              <CustomText className="py-2">
                Thank you for using Timata to purchase your meals.{" "}
                <span className="font-pBold">Your order is on it’s way!</span>
              </CustomText>
            </div>
            <div className="pt-5 ">
              <CustomText className="font-pSemiBold capitalize">
                Order details
              </CustomText>
              <hr className="my-2" />
              <div className="flex justify-between">
                <CustomText className="font-pMedium text-lightTextGray">
                  Order ID:
                </CustomText>
                <CustomText className="font-pSemiBold">
                  #{order?.id ?? "XX"}
                </CustomText>
              </div>
              <hr className="my-2" />
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <div className="flex justify-between">
                    <div>
                      <CustomText className="font-pMedium text-lightTextGray">
                        Purchase date:
                      </CustomText>
                      <CustomText className="font-pSemiBold">
                        {moment(order?.created_at).format("ddd MMM Do, YYYY")}
                      </CustomText>
                    </div>
                    <Divider orientation="vertical" flexItem />
                  </div>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <CustomText className="font-pMedium text-lightTextGray">
                    Delivery date:
                  </CustomText>
                  <CustomText className="font-pSemiBold">
                    {moment()
                      .set({
                        isoWeek: order?.weekNum,
                        day: order?.weekDay,
                        year: order?.weekYear,
                      })
                      .format("ddd MMM Do, YYYY")}
                  </CustomText>
                </Grid>
              </Grid>
              <hr className="my-2" />
              {/* <div className="flex justify-between py-2">
                <CustomText className="font-pRegular">
                  Active subscription:
                </CustomText>
                <CustomText className="font-pRegular">Yes</CustomText>
              </div> */}
              <div className="flex flex-col border border-y p-2 rounded-md bg-bgColor gap-2">
                <div className="flex justify-between py-2 border-b">
                  <CustomText className="font-pMedium">
                    Meals purchased:
                  </CustomText>
                  <CustomText className="font-pSemiBold">
                    {order?.count} Meals
                  </CustomText>
                </div>
                <SelMealsComponent meals={order?.meals ?? []} />
              </div>
              <div className="flex justify-between py-2">
                <CustomText className="font-pMedium">Total amount:</CustomText>
                <CustomText className="font-pSemiBold">${price}</CustomText>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => router("/dashboard")}
            >
              Done
            </Button>
            <CustomText
              className="py-2 underline text-sm hover:text-primary cursor-pointer"
              onClick={() =>
                router(`/activity/${order?.id ?? 0}`, {
                  state: { ...state, order: order },
                })
              }
            >
              View your order status
            </CustomText>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};
