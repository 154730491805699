import { Paper, Toolbar } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const MobilePaper: FC<
  PropsWithChildren<{ className?: string; elevation?: number }>
> = ({ elevation = 2, className = "", children }) => {
  function isSafari() {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  }

  return (
    <Paper
      className={`relative rounded-3xl rounded-b-none flex-1 pb-5 ${className}`}
      elevation={elevation}
    >
      {children}
      {isSafari() ? <Toolbar className="md:hidden block" /> : null}
    </Paper>
  );
};

export default MobilePaper;
