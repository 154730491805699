import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { PaymentCardForm } from "./paymentCard/PaymentCardForm";

export const PaymentAdd = () => {
  return (
    <Layout noFooter={true}>
      <MobilePaper className="p-4 py-6 flex flex-col gap-4">
        <BackTitle title="Add Payment Method" url="" />
        <CustomText className="font-pSemiBold text-lg">
          Credit / Debit Card
        </CustomText>
        <PaymentCardForm />
      </MobilePaper>
    </Layout>
  );
};
