import { Cached } from "@mui/icons-material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import { OrderProcess } from "components/OrderComponent";
import SelMealsComponent from "components/SelMealsComponent";
import Layout from "layouts";
import moment from "moment";
import { BackTitle } from "pages/overview";
import { useLocation } from "react-router-dom";
import { IOrder } from "redux/model";
import { paymentStatusOption } from "utility/variables";

const ActivityDetail = () => {
  const location = useLocation();
  const state = location.state as { order: IOrder; pathName: string };
  const order = state?.order ?? {};
  return (
    <Layout>
      <MobilePaper className="p-4 flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <BackTitle title="Back" url="" tClass="text-lg font-normal" />
          <div className="flex items-center gap-1">
            <Cached />
            <CustomText className="font-pRegular">Reorder</CustomText>
          </div>
        </div>
        <div className="flex flex-col flex-1 basis-0 overflow-y-auto gap-4">
          <CustomText className="text-center font-pRegular text-2xl font-bold">
            Order #{order?.id ?? "XX"}
          </CustomText>
          <CustomText>Order Details</CustomText>
          <div className="border-y border-gray-200 py-3 flex items-center gap-3">
            <div className="flex flex-col w-1/2 gap-2 border-r border-gray-200">
              <CustomText className="text-mBIcon font-pRegular">
                Purchase Date:
              </CustomText>
              <CustomText>
                {moment(order?.created_at).format("DD MMMM YYYY")}
              </CustomText>
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <CustomText className="text-mBIcon font-pRegular">
                Delivery Date:
              </CustomText>
              <CustomText>
                {moment()
                  .set({
                    year: order?.weekYear,
                    isoWeek: order?.weekNum,
                    day: order?.weekDay,
                  })
                  .format("DD MMMM YYYY")}
              </CustomText>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <CustomText className="text-mBIcon font-pRegular">
              Meals Purchased:
            </CustomText>
            <SelMealsComponent meals={state?.order?.meals ?? []} />
          </div>
          <hr />
          <div className="flex justify-between items-center">
            <CustomText className="text-mBIcon">Payment Method</CustomText>
            <CustomText className="font-pMedium">
              {
                paymentStatusOption.find((item) => item?.id === order?.paid)
                  ?.label
              }
            </CustomText>
          </div>
          <div className="flex justify-between items-center gap-4">
            <CustomText className="text-mBIcon whitespace-nowrap">
              Delivery Address
            </CustomText>
            <CustomText className="line-clamp-1 break-all font-pMedium">
              {order?.address?.label}
            </CustomText>
          </div>
          <OrderProcess
            year={order?.weekYear}
            weekNum={order?.weekNum}
            weekDay={order?.weekDay}
          />
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default ActivityDetail;
