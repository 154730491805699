import { Button } from "@mui/material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { useNavigate } from "react-router-dom";

const DeleteAccount = () => {
  const router = useNavigate();
  return (
    <Layout>
      <MobilePaper className="flex flex-col p-4 justify-between">
        <div className="flex flex-col gap-6 h-full">
          <BackTitle title="Delete Account?" url="" />
          <div className="flex flex-col gap-3">
            <CustomText className="text-mBIcon">
              Deleting your account will permanently remove all data associated
              to your Timata account
            </CustomText>
            <div className="p-5 bg-black">
              <CustomText className="font-pMedium text-white text-center">
                Are you sure you wish to permanently delete your account?
              </CustomText>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            className="capitalize"
            size="large"
            onClick={() => router(-1)}
          >
            no, back to dashboard
          </Button>
          <Button
            fullWidth={true}
            variant="contained"
            color="error"
            className="capitalize"
            size="large"
          >
            yes, delete my account
          </Button>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default DeleteAccount;
