import { ADDRESS_RIGHT, DELIVERY_KEY } from "utility/apiSecret";
import axios from "axios";
import { finishLoading, startLoading } from "redux/action";
import freightwayService from "services/freightway.service";
import { APIResponseCode } from "utility/types";

export const addressRightGetAutoComplete = async ({ term = "" }) => {
  return (
    (
      await axios.get(
        `${ADDRESS_RIGHT.BASE_URL}/autocomplete.json?api_key=${
          ADDRESS_RIGHT.API_KEY
        }&term=${term.replace(/ /g, "+")}&type=All`
      )
    )?.data ?? []
  );
};

export const addressDetailInfo = async (
  addressId: string = ""
): Promise<any> => {
  if (!Boolean(addressId)) return {};
  return (
    (
      await axios.get(
        `${ADDRESS_RIGHT.BASE_URL}/address.json?api_key=${ADDRESS_RIGHT.API_KEY}&id=${addressId}`
      )
    )?.data ?? {}
  );
};

export const checkingLocation = async (addressId: string = "") => {
  try {
    startLoading();
    const addressDetail = await addressDetailInfo(addressId);
    const postCode = (addressDetail?.structured_address?.postcode ?? "") * 1;
    const region = addressDetail?.structured_address?.region ?? "";
    // if (postCode < 7000 || postCode > 9999) {
    if (postCode > 9999) {
      finishLoading();
      return { nonUrbanFlag: true, postCode: postCode, region };
    }
    //check the nonUrban address using freightway api
    let url = `${DELIVERY_KEY}/v1/services?lat=${addressDetail?.location?.wgs84_lat}&lon=${addressDetail?.location?.wgs84_lon}&carrierName=PostHaste`;
    const { data, code } = await freightwayService.checkNonUrban({
      url,
      postCode,
    });
    finishLoading();
    if (code === APIResponseCode.FAILED) {
      return { nonUrbanFlag: true, postCode: postCode, region };
    }
    if (data?.["services"]?.[0]?.nonUrban)
      return { nonUrbanFlag: true, postCode: postCode, region };
    else return { nonUrbanFlag: false, postCode: postCode, region };
  } catch (err) {
    console.log(err, "err");
    finishLoading();
    return { nonUrbanFlag: false };
  }
};
