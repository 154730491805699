import ForgotPassword from "pages/auth/ForgotPassword";
import Home from "pages/auth/Home";
import Login from "pages/auth/Login";
import { Logout } from "pages/auth/Logout";
import SingUp from "pages/auth/Register";
import PublicRoute from "routes/PublicRoute";
import ResetPassword from "pages/reset-password";

const routes = [
  {
    path: "/",
    element: (
      <PublicRoute>
        <Home />
      </PublicRoute>
    ),
  },
  {
    path: "/test",
    element: <ResetPassword />,
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/register",
    element: (
      <PublicRoute>
        <SingUp />
      </PublicRoute>
    ),
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
];

export default routes;
