import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import wLogo from "assets/img/logo/logo-text-white.svg";
import logo from "assets/img/logo/logo-text.svg";
import blackLogo from "assets/img/logo/black-logo.svg";
import { FC } from "react";
import CustomImage from "./CustomImage";
import CustomLink from "./CustomLink";

interface LogoProps {
  helpIcon?: boolean;
  variant?: string;
  size?: string;
  className?: string;
  shadow?: boolean;
  target?: string;
  href?: string;
  logoType?: string;
}

const Logo: FC<LogoProps> = ({
  target = "_self",
  href = "/",
  className = "",
  size = "medium",
  helpIcon = false,
  logoType = "primary",
}) => {
  const width =
    size === "tiny"
      ? 48
      : size === "small"
      ? 96
      : size === "medium"
      ? 144
      : 192;
  return (
    <div className="flex items-center justify-between">
      <CustomLink href={href} target={target}>
        <div className={className}>
          <CustomImage
            src={
              logoType === "white"
                ? wLogo
                : logoType === "black"
                ? blackLogo
                : logo
            }
            alt="timata logo"
            width={width}
            height={width}
          />
        </div>
      </CustomLink>
      {helpIcon && <HelpOutlineOutlinedIcon fontSize="large" color="primary" />}
    </div>
  );
};

export default Logo;
