import { IUserProfile } from "redux/model";

export const INITIAL_USER_PROFILE: IUserProfile = {
  id: "",
  phone: "",
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  birthday: "",
  address: {
    label: "",
    id: "",
    value: "",
  },
  balance: 0,
  created_at: "",
};
