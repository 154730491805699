import { CalendarMonthOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment, { Moment } from "moment";
import * as React from "react";

const CustomDatePicker = ({
  data = "",
  helperText = "",
  onChange = () => null,
}: {
  data?: string;
  helperText?: string;
  onChange?: (newDate: string) => void;
}) => {
  const [value, setValue] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    if (!data) return;
    setValue(moment(data, "DD/MM/YYYY"));
  }, [data]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nz">
        <DatePicker
          className="w-full"
          value={value}
          slotProps={{
            textField: {
              error: Boolean(helperText),
              helperText: helperText,
              InputProps: {
                endAdornment: <CalendarMonthOutlined />,
              },
            },
          }}
          views={["year", "month", "day"]}
          format="DD.MM YYYY"
          onChange={(newValue: Moment | null) =>
            onChange(moment(newValue).format("DD/MM/YYYY"))
          }
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomDatePicker;
