import AddOrder from "pages/order/AddOrder";
import Overview from "pages/overview/Overview";
import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    path: "/order/add",
    element: (
      <PrivateRoute>
        <AddOrder />
      </PrivateRoute>
    ),
  },
  {
    path: "/overview",
    element: (
      <PrivateRoute>
        <Overview />
      </PrivateRoute>
    ),
  },
];

export default routes;
