import { IMealS } from "utility/types";
import createReducer from "./createReducer";
import { MealActions,MealActionTypes } from "../model/meal";

export const meal = createReducer<IMealS>({} as IMealS, {
  [MealActions.FETCH_MEAL](state: {}, action: MealActionTypes) {
    return action.payload;
  },
  [MealActions.SET_MEAL](state: {}, action: MealActionTypes) {
    return action.payload;
  },
  [MealActions.UPDATE_MEAL](state: {}, action: MealActionTypes) {
    return {
      ...state,
      ...action.payload,
    };
  },
});
