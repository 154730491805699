/* eslint-disable no-prototype-builtins */
/**
 * Created by Minh on 15.11.2020.
 */
import { Reducer } from "redux";
import { Action } from "redux/model";

// eslint-disable-next-line import/no-anonymous-default-export
export default function <S>(initialState: S, handlers: any): Reducer<S> {
	const r = (state: S = initialState, action: Action): S => {
		if (handlers.hasOwnProperty(action.type)) {
			return handlers[action.type](state, action);
		} else {
			return state;
		}
	};

	return r as Reducer<S>;
}
