export const apiPath = {
  USER_LOGIN: `/user/login`,
  USER_ADMIN_LOGIN: `/user/admin/login`,
  USER_REGISTER: `/user/register`,
  USER_LOGIN_WITH_CODE: `/user/login/code`,
  USER_FORGOT_PASSWORD: `/user/forgot-password`,
  USER_RESET_PASSWORD: `/user/reset-password`,
  USER_VERIFY_OTP_TIWILIO: `/user/register/otp/verify`,
  USER_ACCCOUNT_VALIDATIOIN: `/user/account-validation`,
  ATTACH_PAYMENT_CARD: `/user/payment-card/add`,
  DETACH_PAYMENT_CARD: `/user/payment-card/remove`,
  PAYMENT_METHODS: `/user/paymentMethods`,
  CHECK_PROMOCODE: `/user/promocode`,
  USER_REMOVE: `/user/delete`,
  USER_UPDATE: `/user/update`,

  CREATE_ORDER: `/order/add`,
  CREATE_ORDER_PROMO: `/order/add/promocode`,
  GET_ORDERS: "/order/all",
  GET_USER_ORDER: "/order/user",
  CALC_DELIVERY_DATES: "/order/calcDeliveryDates",

  GET_MEALS: "/meal/all",
  GET_MEAL_PRICES: "/meal/box/prices",
  BLACKOUT_DAYS: "/blackoutDay/all",

  FREIGHTWAY_SERVICE: '/freightway/service',

  WAITLIST_JOIN: '/queue/join',
};
