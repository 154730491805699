// import { Z_INDEX_HEADER } from 'utility/variables'
import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "components/Logo";
import { NavLink } from "react-router-dom";
import { Z_INDEX_HEADER } from "utility/variables";

export const HeaderLink = ({
  href = "/",
  text,
}: {
  href: string;
  text: string;
}) => {
  return (
    <NavLink
      className="whitespace-nowrap text-white hover:underline no-underline px-3 uppercase hover:text-[#EEBB4D] font-pSemiBold text-xl"
      to={href}
    >
      {text}
    </NavLink>
  );
};

const Header = () => {
  return (
    <Box
      sx={{ zIndex: (theme: any) => theme.zIndex.drawer + Z_INDEX_HEADER }}
      className="max-w-full"
    >
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        component="nav"
        sx={{ background: "transparent", boxShadow: "none", top: 0, left: 0 }}
        className="flex items-center"
      >
        <Toolbar className="w-full mb:w-[444px] flex justify-between bg-bgColor py-5 pl-3">
          <div className="relative">
            <Logo href="/" logoType="black" />
          </div>
          {/* <div className="items-center hidden md:flex">
            <div className="md:hidden relative flex justify-end w-full">
              <NotificationsNoneOutlined
                className="mr-2 text-black"
                fontSize="large"
              />
              <AccountCircleOutlined className="text-black" fontSize="large" />
            </div>
            <CustomLink href="/order/add">
              <Button
                className="whitespace-nowrap font-pSemiBold text-lg mx-3"
                variant="contained"
                color="warning"
              >
                ADD A ORDER
              </Button>
            </CustomLink>
            <CustomLink href="/meals">
              <Button
                className="whitespace-nowrap font-pSemiBold text-lg mx-3"
                variant="contained"
                color="warning"
              >
                OUR MEALS
              </Button>
            </CustomLink>
            <HeaderLink href="/subscriptions" text="subscriptions" />
            <HeaderLink href="/account" text="account" />
            <HeaderLink href="/support" text="help" />
            <div className="flex items-center cursor-pointer">
              <Logout fontSize="large" className="mr-2 text-white" />
              <CustomText className="font-pSemiBold text-lg text-white">
                LOG OUT
              </CustomText>
            </div>
          </div> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
