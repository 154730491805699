import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { addMinus } from "utility/types";
import CustomText from "./CustomText";

const CountButton = ({
  count = 0,
  nextDisable = false,
  onClick = () => {},
}: {
  count?: number;
  nextDisable: boolean;
  onClick?: (type: addMinus) => void;
}) => {
  return (
    <div
      className={`items-center inline-flex rounded border-black border overflow-hidden`}
    >
      <div
        className={`bg-bgColor px-3 py-1 ${
          count === 0 ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => (count > 0 ? onClick("minus") : () => {})}
      >
        <RemoveOutlined />
      </div>
      <CustomText className="text-white bg-primary py-1.5 px-4">
        {count}
      </CustomText>
      <div
        className={`bg-bgColor px-3 py-1 ${
          !nextDisable ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => (nextDisable ? onClick("plus") : () => {})}
      >
        <AddOutlined />
      </div>
    </div>
  );
};

export default CountButton;
