import { useSelector } from "react-redux";
import { Navigate, RouteProps } from "react-router-dom";
import { RootState } from "redux/reducers";

const PublicRoute = ({ children }: RouteProps) => {
  const token = useSelector(
    (state: RootState) => state?.userProfile?.token ?? ""
  );
  if (token) {
    return <Navigate to="/dashboard" replace />;
  }
  return <>{children}</>;
};
export default PublicRoute;
