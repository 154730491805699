import { Meal, TDeliveryDate } from "utility/types";
import { addressRightProps } from "./userProfile";

export interface IOrder {
  id: string;
  deliveryDate: TDeliveryDate;
  weekDay: number;
  weekNum: number;
  weekYear: number;
  address: addressRightProps;
  count: number;
  meals: { meal: Meal; count: number }[];
  weekly: boolean;
  paymentMethod: any;
  paymentIntent: any;
  userId: string;
  promocode?: string;
  credit: number;
  card: number;
  paid: string;
  created_at: string;
}

export interface IOrderS {
  order: IOrder;
  step?: number;
  selMeal?: Meal;
}

export enum OrderActions {
  FETCH_ORDER = "FETCH_ORDER",
  SET_ORDER = "SET_ORDER",
  UPDATE_ORDER = "UPDATE_ORDER",
}

interface IOrderActions<T, P> {
  type: T;
  payload: P;
}

export type OrderActionTypes =
  | IOrderActions<typeof OrderActions.FETCH_ORDER, IOrderS>
  | IOrderActions<typeof OrderActions.SET_ORDER, IOrderS>
  | IOrderActions<typeof OrderActions.UPDATE_ORDER, IOrderS>;
