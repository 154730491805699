import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import visaCard from "assets/img/card/visa.svg";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import TimataWallet from "components/TimataWallet";
import Layout from "layouts";
import { BackTitle } from "pages/overview";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { OrderActions, UserProfileActions } from "redux/model";
import { RootState } from "redux/reducers";
import orderService from "services/order.service";
import userService from "services/user.service";
import { APIResponseCode, eventType } from "utility/types";

interface IPayData {
  credit: number;
  card: number;
  paid: string;
}

export const PaymentMethods = ({
  paymentMethods = [],
  value = "",
  payType = true,
  onChange = () => null,
}: {
  paymentMethods: any[];
  value: string;
  payType?: boolean;
  onChange?: (e: eventType) => void;
}) => {
  const router = useNavigate();
  return (
    <div
      className={`bg-bgColor rounded py-2 px-4 border hover:shadow ${
        payType ? "" : "border-primary"
      }`}
    >
      {paymentMethods.length > 0 ? (
        <RadioGroup value={value} onChange={onChange}>
          {paymentMethods.map((item, index) => (
            <FormControlLabel
              className="w-full"
              value={item?.id}
              control={<Radio />}
              componentsProps={{ typography: { width: "100%" } }}
              key={index}
              label={
                <div
                  className="flex items-center justify-between py-2 w-full"
                  key={index}
                >
                  <CustomText className="text-sm">
                    {item?.card?.last4
                      ? `Ending in: ....${item?.card?.last4}`
                      : "No payment method added."}
                  </CustomText>
                  <CustomImage
                    className="w-12 h-auto"
                    src={visaCard}
                    alt="visa card image"
                  />
                </div>
              }
            />
          ))}
        </RadioGroup>
      ) : (
        <div className="flex items-center justify-between">
          <CustomText className="text-sm">No payment method added.</CustomText>
          <Button
            variant="contained"
            onClick={() => router("/checkout/payment/add")}
          >
            Add now
          </Button>
        </div>
      )}
    </div>
  );
};

export const PriceText = ({
  text,
  tColor = "",
  tClassName = "",
  value,
  vClassName = "",
}: {
  text: string | ReactNode;
  tColor?: string;
  tClassName?: string;
  value: string;
  vClassName?: string;
}) => {
  return (
    <div className="flex justify-between items-center">
      <CustomText
        className={`font-pRegular py-1 ${
          tColor ? `text-${tColor}` : "text-mBIcon"
        } ${tClassName}`}
      >
        {text}
      </CustomText>
      <CustomText className={vClassName}>{value}</CustomText>
    </div>
  );
};

export const Checkout = () => {
  const router = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [payType, setPayType] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selPaymentMethod, setSelPaymentMethod] = useState<string>("");
  const orderP = useSelector((state: RootState) => state?.order ?? {});
  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );
  const price = useSelector(
    (state: RootState) => state?.meal?.selMealPrice?.totalPrice ?? "XX.XX"
  ) as unknown as number;

  const split = useMemo(
    () => (payType && user.balance*1 < price * 1 ? true : false),
    [payType, price, user]
  );

  const handlePay = async (v: IPayData) => {
    startLoading();
    const paymentMethod = paymentMethods.find(
      (item) => item?.id === selPaymentMethod
    );
    const orderData = {
      ...orderP.order,
      paymentMethod: paymentMethod?.id ? paymentMethod : null,
      weekly: orderP.order?.weekly ?? true,
      ...v,
    };
    const res = await orderService.save({
      data: orderData,
      userId: user?.id ?? "1",
      count: orderP.order.count ?? 6,
      customerId: user?.customerId ?? "cus_No9vsYz6W6wVNH",
    });
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      dispatch({
        type: UserProfileActions.UPDATE_USER_PROFILE,
        payload: { user: { ...user, balance: res.data?.balance ?? 0 } },
      });
      dispatch({ type: OrderActions.SET_ORDER, payload: {} });
      router("/checkout/success", {
        state: {
          ...state,
          order: res?.data?.order ?? {},
          price: res?.data?.price,
        },
      });
    } else router("/checkout/failed");
  };

  const handlePayButtonClick = () => {
    const payData = !payType
      ? { credit: 0, card: price, paid: "1" }
      : !split
      ? { credit: price, card: 0, paid: "5" }
      : ({} as IPayData);
    return split ? router("/checkout/split_payment") : handlePay(payData);
  };

  const loadPaymentMethods = async () => {
    if (user.customerId) {
      const res = await userService.getPaymentMethods({
        customerId: user?.customerId ?? "cus_No9vsYz6W6wVNH",
      });
      if (res.code === APIResponseCode.SUCCESS) {
        dispatch({
          type: UserProfileActions.UPDATE_USER_PROFILE,
          payload: { paymentMethods: res.data },
        });
        setPaymentMethods(res.data);
      } else toast.warning(res.message);
    }
  };

  const handleChangePaymentMethod = (e: eventType) => {
    if (payType) setPayType(false);
    const paymentMethod = paymentMethods.find(
      (item) => item?.id === e.target.value
    );
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: { ...orderP.order, paymentMethod: paymentMethod } },
    });
  };

  useEffect(() => {
    loadPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelPaymentMethod(orderP?.order?.paymentMethod?.id ?? "");
  }, [orderP]);

  return (
    <Layout>
      <MobilePaper className="p-4 py-6 flex flex-col gap-4">
        <BackTitle title="Checkout" url="" />
        <div className="flex flex-col gap-4">
          <PriceText
            text={`${orderP.order?.count} x Meals`}
            value={`$${price}`}
          />
          <PriceText text="Delivery fee:" value="Free" />
          <hr />
          <PriceText
            text="Total Cost"
            value={`$${price}`}
            vClassName="font-pSemiBold text-xl"
          />
          <hr />
          <CustomText className="text-right text-mBIcon font-pRegular text-base">
            Price Includes GST
          </CustomText>
        </div>
        <div className="flex flex-col gap-4">
          <CustomText className="font-pSemiBold">
            {payType ? "Voucher Code:" : "Select payment method:"}
          </CustomText>
          <TimataWallet
            payType={payType}
            price={price * 1}
            onClick={() => {
              setPayType(true);
              setSelPaymentMethod("");
            }}
          />
          <PaymentMethods
            paymentMethods={paymentMethods}
            value={selPaymentMethod}
            payType={payType}
            onChange={handleChangePaymentMethod}
          />
        </div>

        <div>
          <div className="flex justify-end">
            {orderP?.order?.paymentMethod?.card && (
              <CustomText
                className="underline cursor-pointer py-2 text-sm"
                onClick={() => router("/checkout/payment/add")}
              >
                Add new payment method
              </CustomText>
            )}
          </div>
          <Button
            variant="contained"
            size="large"
            fullWidth={true}
            disabled={!payType ? !Boolean(selPaymentMethod) : false}
            onClick={handlePayButtonClick}
            className="normal-case"
          >
            {split ? "Split Payment with Card" : "Pay Now"}
          </Button>
        </div>
      </MobilePaper>
    </Layout>
  );
};
