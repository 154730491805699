import { CancelOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { PaymentMethods } from "pages/checkout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers";

const PaymentFailed = () => {
  const orderP = useSelector((state: RootState) => state?.order ?? {});
  const router = useNavigate();
  return (
    <Layout>
      <MobilePaper className="p-4 py-6 flex flex-col justify-between">
        <div className="flex justify-between flex-col">
          <div>
            <div className="text-center">
              <CancelOutlined className="text-red-600" fontSize="large" />
              <CustomText className="font-pBold text-2xl">
                Purchase failed!
              </CustomText>
              <CustomText className="py-2">
                There was a problem with your payment method. Check your data
                and try again.
              </CustomText>
            </div>
            <div className="pt-5 ">
              <CustomText className="font-pSemiBold">
                Check your payment method
              </CustomText>
              <CustomText className="py-2">
                <PaymentMethods
                  paymentMethods={[orderP?.order?.paymentMethod]}
                  value={orderP?.order?.paymentMethod?.id}
                  payType={false}
                />
              </CustomText>
              <div className="flex items-center">
                <CustomText
                  className="underline cursor-pointer py-2 text-sm"
                  onClick={() => router(-1)}
                >
                  Select Different Payment Method
                </CustomText>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              className="bg-black text-white mt-5"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => router("/support")}
            >
              Contact Timata Help
            </Button>
            <CustomText
              className="py-2 underline text-sm"
              onClick={() => router(-1)}
            >
              Back to groceries
            </CustomText>
          </div>
        </div>
      </MobilePaper>
    </Layout>
  );
};

export default PaymentFailed;
