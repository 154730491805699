import { Checkout, CheckoutPromoCode, PaymentAdd } from "pages/checkout";
import { PaymentStatus } from "pages/paymentStatus";
import PaymentFailed from "pages/paymentStatus/PaymentFailed";
import PrivateRoute from "./PrivateRoute";
import SplitPayment from "pages/splitPayment";

const routes = [
  {
    path: "/checkout",
    element: (
      <PrivateRoute>
        <Checkout />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout/promocode",
    element: (
      <PrivateRoute>
        <CheckoutPromoCode />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout/payment/add",
    element: (
      <PrivateRoute>
        <PaymentAdd />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout/success",
    element: (
      <PrivateRoute>
        <PaymentStatus />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout/failed",
    element: (
      <PrivateRoute>
        <PaymentFailed />
      </PrivateRoute>
    ),
  },
  {
    path: "/checkout/split_payment",
    element: (
      <PrivateRoute>
        <SplitPayment />
      </PrivateRoute>
    ),
  },
];

export default routes;
