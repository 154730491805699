import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import { Button } from "@mui/material";
import AddressPickerComponent from "components/AddressPickerComponent";
import CustomText from "components/CustomText";
import { checkingLocation } from "models/third/addressRight";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { IOrder, addressRightProps } from "redux/model";
import { RootState } from "redux/reducers";
import waitlistService from "services/waitlist.service";
import { APIResponseCode } from "utility";

export interface IOnChangeAddress {
  address: addressRightProps;
  nonUrbanFlag?: boolean;
}

const DeliveryAddress = ({
  data = {} as IOrder,
  name = "address",
  nonUrban = true,
  onChange = () => null,
  onNext = () => null,
}: {
  data: IOrder;
  name?: string;
  nonUrban: boolean;
  onNext: () => void;
  onChange: ({ address, nonUrbanFlag }: IOnChangeAddress) => void;
}) => {
  const router = useNavigate();
  const state = useLocation();
  const [selectedItem, setSelectedItem] = useState<addressRightProps>(
    {} as addressRightProps
  );

  const user = useSelector(
    (state: RootState) => state?.userProfile?.user ?? {}
  );

  const handleSelectItem = async (item: addressRightProps) => {
    if (item?.id && item.label !== selectedItem.label) {
      setSelectedItem(item);
      if (item.label !== selectedItem.label)
        try {
          const {
            nonUrbanFlag,
            postCode,
            region = "",
          } = await checkingLocation(item?.id);
          const address = {
            ...item,
            id: item.id,
            postCode,
            region,
          } as addressRightProps;
          onChange({ nonUrbanFlag, address });

          // if (nonUrbanF  lag && type !== 'register')
          //   return             return onChange(addressInfo);
        } catch (err) {
          console.log(err, "err");
        }
    }
  };

  const handleWaitlist = async () => {
    startLoading();
    const {
      data: res,
      code,
      message,
    } = await waitlistService.joinWaitlist({
      userId: user.id,
      postCode: data?.address?.postCode ?? 0,
      address: data.address,
    });
    finishLoading();
    if (code === APIResponseCode.SUCCESS) {
      if (res.exist) toast.info("Already joined waitlits");
      else toast.success("Joined waitlist successfully");
      router("/dashboard", { state: { ...state, pathName: "dashboard" } });
    } else toast.error(message);
  };

  useEffect(() => {
    handleSelectItem(data.address);
    //eslint-disable-next-line
  }, [data]);

  return (
    <div className="flex flex-col justify-between">
      <div>
        <CustomText className="font-pSemiBold text-xl">
          Delivery Address
        </CustomText>
        <CustomText className="font-pSemiBold pb-3 text-grayBlack">
          Please enter your delivery address:
        </CustomText>
        <AddressPickerComponent
          value={data?.address ?? null}
          placeholder="36 Grant Road, Queenstown, 9300"
          onChange={handleSelectItem}
        />
      </div>
      <div className="flex flex-col gap-2">
        {nonUrban ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col py-3 text-center gap-2 items-center bg-bgColor rounded-md">
              <CancelOutlined color="error" fontSize="large" />
              <CustomText className="text-lg font-pSemiBold">Sorry!</CustomText>
              <CustomText className="text-sm">
                We can't currently deliver to your address. <br />
                Please use a South Island Urban Address.
              </CustomText>
            </div>
            <Button
              color="primary"
              variant="contained"
              fullWidth={true}
              className="normal-case"
              size="large"
              onClick={handleWaitlist}
            >
              Join the Waitlist
            </Button>
          </div>
        ) : (
          <div className="bg-bgColor rounded text-center p-4 my-4">
            <TaskAlt className="text-green" fontSize="large" />
            <CustomText className="font-pSemiBold text-lg">
              Good news!
            </CustomText>
            <CustomText className="font-pRegular text-sm">
              We deliver to your address
            </CustomText>
          </div>
        )}
        <Button
          className="w-full"
          variant="contained"
          disabled={nonUrban}
          size="large"
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default DeliveryAddress;
