import CustomImage from "./CustomImage";
import CustomText from "./CustomText";

const SelMealsComponent = ({ meals }) => {
  return (
    <div>
      {meals.map((item, index) => (
        <div key={index} className="flex items-center justify-between">
          <CustomText className="font-pMedium text-base">
            {item.meal.name}
          </CustomText>
          <div className="flex items-center">
            {Array.from(Array(item.count).keys()).map((i) => (
              <CustomImage
                src={item.meal.image}
                alt=""
                key={i}
                height={40}
                width={40}
                className="-ml-4"
              />
            ))}
            <CustomText className="text-primary">
              &nbsp;x&nbsp;{item.count}
            </CustomText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelMealsComponent;
