import { Autocomplete, Box, TextField } from '@mui/material'

export type option = {
  value?: string | number | null
  label: string
  id?: string | number | null
  code?: string
  phone?: string
  currency?: string
  language?: string
}

const PhoneCodeAutoComplete = ({
  options = [],
  value = '',
  onChange = () => null,
}: {
  label?: string
  options: option[]
  value: string
  onChange?: (newValue: string) => void
}) => {
  return (
    <Autocomplete
      componentsProps={{
        paper: {
          style: { width: 250 },
        },
      }}
      options={options}
      getOptionLabel={(option) => option?.code ?? ''}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${(
              option?.code ?? 'NZ'
            ).toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${(
              option?.code ?? 'NZ'
            ).toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      onChange={(event, newValue) => {
        console.log(newValue)
        onChange(newValue?.phone ?? '')
      }}
      value={options.find((item) => item.phone === value)}
      disableClearable
    />
  )
}

export default PhoneCodeAutoComplete
