import userService from "services/user.service";
import { ENCRYPT_KEY } from "utility/apiSecret";
import { Decrypt, validateEmail, validatePhoneNumber } from "utility/helper";
import { errorProps, userInfoProps } from "utility/types";

export const emailPhoneValidation = ({
  type = "email",
  verifyData,
  phoneCode = "+64",
}: {
  type: "email" | "phone";
  verifyData: string;
  phoneCode?: string;
}) => {
  if (!verifyData)
    return { valid: false, phoneEmail: "Please enter a phone number or email" };
  //checking validation
  const validation =
    type === "email"
      ? validateEmail(verifyData)
      : validatePhoneNumber(`+${phoneCode}${verifyData}`);
  if (!validation)
    return { valid: false, [type]: `The ${type} you entered is invalid.` };
  return { valid: true };
};

export const otpValidation = async ({
  type = "email",
  verifyData,
  phoneCode = "+64",
  data = {} as userInfoProps,
}: {
  type: "email" | "phone";
  verifyData: string;
  phoneCode?: string;
  data: userInfoProps;
}) => {
  if (!data?.confirm_code)
    return { valid: false, confirm_code: "Please enter an otp code" };
  if (type === "phone") {
    const res = await userService.verifyOtp({
      type,
      verifyData: `+${phoneCode}${verifyData}`,
      otp: data?.confirm_code ?? "",
    });
    if (!res?.data) {
      return { valid: false, confirm_code: res.message };
    }
  } else {
    const otp = Decrypt(localStorage.getItem("otpCode") ?? "", ENCRYPT_KEY);
    if (otp !== data?.confirm_code)
      return { valid: false, confirm_code: "The OTP code is not matched" };
  }
  return { valid: true };
};

export const passwordValidation = ({
  data = {} as userInfoProps,
}: {
  data: userInfoProps;
}) => {
  let error = {} as errorProps;
  const validationFields = ["password", "confirmPassword"];
  (validationFields as (keyof userInfoProps)[]).map((key) => {
    return (error = {
      ...error,
      [key]: data[key] ? "" : "This field is required",
    });
  });
  const checkValidation =
    (validationFields as (keyof errorProps)[])
      .map((key) => Boolean(data?.[key]))
      .filter((item) => !item)?.length > 0
      ? true
      : false;
  if (checkValidation) return { valid: false, ...error };
  if (data?.password !== data?.confirmPassword)
    return { valid: false, compare: "The password is not same!" };
  return { valid: true, compare: "", ...error };
};
