import CustomText from "components/CustomText";
import PhoneTextInput from "components/PhoneTextInput";
import { errorProps, phoneInputProps } from "utility/types";
import { FormControl, FormLabel, TextField } from "@mui/material";

export const EmailPhoneSection = ({
  authType = "create",
  error = {} as errorProps,
  phoneCode = "64",
  data = "",
  type = "email",
  onChange = () => null,
  onChangePhoneCode = () => {},
}: phoneInputProps & { authType?: string }) => {
  return (
    <>
      <div>
        <CustomText className="font-pSemiBold" variant="h4">
          {authType === "forgot-password"
            ? "Forgot Password"
            : "Phone or Email"}
        </CustomText>
        <CustomText className="leading-6 pt-2 text-lg text-grayBlack">
          Enter your phone number or email{" "}
          {authType === "create" ? "to create an account" : ""}
        </CustomText>
      </div>
      <div className="py-8">
        <FormControl fullWidth>
          <FormLabel>Phone or Email</FormLabel>
          {type === "email" ? (
            <TextField
              value={data}
              onChange={onChange}
              name="email"
              type="text"
              fullWidth
              autoFocus
              error={Boolean((error?.email ?? "") || error?.phoneEmail)}
              helperText={(error?.email ?? "") || error?.phoneEmail}
              required
            />
          ) : (
            <PhoneTextInput
              phoneCode={phoneCode}
              data={data}
              onChangePhoneCode={onChangePhoneCode}
              onChange={onChange}
              type={type}
              error={error}
            />
          )}
        </FormControl>
      </div>
    </>
  );
};
