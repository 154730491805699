import { TaskAlt } from "@mui/icons-material";
import CustomImage from "components/CustomImage";
import CustomText from "components/CustomText";
import MobilePaper from "components/MobilePaper";
import Layout from "layouts";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers";
import LOGO from "assets/img/logo/logo.svg";
import { Button } from "@mui/material";

const AddedCredit = () => {
  const { state = {} } = useLocation();
  const router = useNavigate();
  const user = useSelector((state: RootState) => state.userProfile.user);
  return (
    <Layout noFooter={true}>
      <MobilePaper className="p-4 flex flex-col justify-between">
        <div className="flex flex-col h-full gap-4">
          <div className="flex flex-col text-center items-center gap-3">
            <TaskAlt color="success" fontSize="large" />
            <CustomText className="text-2xl font-pSemiBold">
              purchase successful!
            </CustomText>
            <CustomText className="text-base font-pMedium">
              Credit has been added to your account successfully.
            </CustomText>
          </div>
          <hr />
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <CustomText className="text-mBIcon">Voucher Code:</CustomText>
              <CustomText className="text-primary">
                {state?.code ?? ""}
              </CustomText>
            </div>
            <div className="flex justify-between">
              <CustomText className="text-mBIcon text-lg">
                Total Credit Added:
              </CustomText>
              <CustomText className="font-pSemiBold text-xl">
                ${state?.amount ?? ""}
              </CustomText>
            </div>
          </div>
          <hr />
          <div className="p-3 flex justify-between border border-primary rounded-md">
            <div className="flex flex-col gap-2">
              <CustomText className="text-base font-medium">
                Timata Wallet
              </CustomText>
              <CustomText className="text-sm font-medium text-mBIcon">
                Current Balance ${user.balance}
              </CustomText>
            </div>
            <CustomImage src={LOGO} alt="" height={40} width={"auto"} />
          </div>
        </div>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          className="capitalize"
          onClick={() => router(-1)}
        >
          Done
        </Button>
      </MobilePaper>
    </Layout>
  );
};

export default AddedCredit;
